import { FaAddressBook, FaPhone, FaEnvelope, FaInstagram, FaPrint } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Config from '../Header/Config';
export default function Invoice() {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [grandTotal, setGrandTotal] = useState('');
  const [discount, setDiscount] = useState('');
  const [subTotal, setSubTotal] = useState('');
  const [invoice, setInvoice] = useState('');
  const [tableData, setTableData] = useState('');
const [BuyBackData,setBuyBackData]=useState([]);

const [BuyBackModel,setBuyBackModel]=useState('');
const [loading, setLoading] = useState(true);
  //................................{ set data in invoice }...............................
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invoice_Number = searchParams.get('invoice_no');
  const invoice_brand = searchParams.get('invoice_no');
 
  //......................{ fetch brand details }.......................

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_SalesInvoice/${invoice_Number}`);
        const data = await response.json();

        const tableData = data.data.filter((item) => item.invoice_no === invoice_Number);
        console.log(tableData);

        setTableData(tableData);
         
        setName(tableData[0].customer_name)
        setDate(tableData[0].date)
        setMobileNumber(tableData[0].mobile_no)
        setGrandTotal(tableData[0].grand_total)
        setDiscount(tableData[0].discount)
        setSubTotal(tableData[0].sub_total)
        setInvoice(tableData[0].invoice_no)

        setLoading(false);
      
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [invoice_Number]);

  const navigate = useNavigate();
  const apiUrl = Config.apiUrl

  const handleBack = () => {
    navigate("/sale_table");
  };

  const [showDiv, setShowDiv] = useState(true);

  const handlePrint = () => {
    setShowDiv(false);
    setTimeout(() => {
      window.print();
      setShowDiv(true);
    }, 0);
  };

  useEffect(() => {
    const handleBeforePrint = () => {
      setShowDiv(false);
    };

    const handleAfterPrint = () => {
      setShowDiv(true);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  //..........................................................

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_returnBrand`);
        const data = await response.json();

        const BuyBackData = data.data.filter((item) => item.invoice_no === invoice_Number);
        console.log(BuyBackData);
        setBuyBackData(BuyBackData)

        setBuyBackModel(BuyBackData[0].model_name)
        
      
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [invoice_Number]);


  return (


    <>
    {loading ? ( // Render loading message when loading is true
          <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"100px"}}>Loading...</div>

          ) : (
      <section style={{ backgroundColor: "white" }}>
        <div style={{ margin: 'auto', padding: '30px 24%', position: 'relative' }}>
       
          {showDiv && (
            <>
              <div style={{ margin: "auto", padding: "0px 0px", position: "relative", maxWidth: "210mm" }}>
                <div style={{ float: "left" }}>
                  <button className='btn btn-outline-primary' onClick={handleBack}>Back</button>
                </div>
                <div style={{ float: "right" }}>
                  <button className='btn btn-outline-danger' onClick={handlePrint}><FaPrint /></button>
                </div>
              </div>
            </>
          )}
        </div>



        <div className="invoice-box ">
          <div className="invoice-header">
            <div className="flex">
              <table style={{ border: "1px solid #dddddd" }}>
                <tbody >
                  <tr>

                    <p className="arihant" style={{ margin: "5px 0 0 0" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "70%" }}>
                          <img style={{ paddingTop: "0px" }} src="assets/img/a4.jpg" width="200" height="120" />

                        </div>

                      </div>
                    </p>
                    <td style={{ width: "40%" }}>
                      <p style={{ lineHeight: " 1.2" }}>
                        <p><FaAddressBook /> Baburao</p>
                        <p><FaPhone /> +91 9006500607</p>
                        <p><FaInstagram /> @Mobilecitygadhinglaj</p>

                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table>
              <tr>
                <td> <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                  <img src="https://www.pngkey.com/png/detail/107-1074224_free-apple-logo-png-transparent-background-essential-iphone.png" style={{ width: "120px", height: "30px", marginRight: "10px" }} alt="iPhone" />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/OnePlus_logo.png" style={{ width: "120px", height: "30px", marginRight: "10px" }} alt="OnePlus" />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Samsung_Logo.svg/2560px-Samsung_Logo.svg.png" alt="Samsung" style={{ width: "120px", height: "25px", marginRight: "10px" }} />
                  <img src="https://banner2.cleanpng.com/20180412/ysq/kisspng-vivo-logo-smartphone-branding-5acf57274b6e50.967449261523537703309.jpg" alt="Vivo" style={{ width: "80px", height: "25px", marginRight: "10px" }} />
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQhZy3P7TFqM1fUohEYPLfbN1N3_oIUzfUqaZhVq8&s" alt="Xiaomi" style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                  <img src="https://upload.wikimedia.org/wikipedia/commons/a/a2/OPPO_LOGO_2019.png" alt="Oppo" style={{ width: "100px", height: "35px", marginRight: "10px" }} />
                </div></td>
              </tr>
              <tr>
                <td style={{ backgroundColor: "	#531aff", color: "white" }}>
                  <center ><b>Doctor colony Near tejam hospital Gahdhinglaj dist-kolhapur Pin- 416 502 </b></center>
                </td>
              </tr>
            </table>

            <table>
              <tbody >
                <tr>
                  <td style={{ width: "50%", paddingLeft: "10px" }}>
                    <b>Name: {name}</b>
                    <br />

                    <b>Mobile No:{mobileNumber} </b>

                    <br />
                  </td>
                  <td style={{ width: "50%" }}>
                    <b>Date: {date}</b>
                    <br />
                    <b>Invoice No: {invoice_Number}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table className="tables">
            <thead>
              <tr style={{ backgroundColor: "#FF3399", color: "white" }}>
                <th style={{ width: "10%", border: "1px solid #dddddd", textAlign: "center" }}>Sr No</th>
                <th style={{ width: "60%", border: "1px solid #dddddd", textAlign: "center" }}>Product</th>
                <th style={{ width: "10%", border: "1px solid #dddddd", textAlign: "center" }}>Qty.</th>
                <th style={{ border: "1px solid #dddddd", textAlign: "center" }}>Amount</th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid #dddddd" }}>
              {Array.isArray(tableData) &&
                tableData.map((row, index) => (
                  <tr key={index} style={{ border: "1px solid #dddddd" }}>
                    <td style={{ border: "1px solid #dddddd", textAlign: "center" }}>{index + 1}</td>
                    <td style={{ border: "1px solid #dddddd" }}>
                      <b>Model:</b>  {row.model_name}
                      <br />
                      <b> IMEI: </b>{row.imei}
                    </td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "center" }}>{1}</td>
                    <td style={{ border: "1px solid #dddddd", textAlign: "center" }}>&#8377;{row.price}/-</td>
                  </tr>
                ))}
                
            </tbody>
            
          </table>
          {Array.isArray(BuyBackData) && BuyBackData.length > 0 && (
  <table className="tables">
    <tbody style={{ border: "1px solid #dddddd" }}>
      {BuyBackData.map((row, index) => (
        <tr key={index} style={{ border: "1px solid #dddddd" }}>
          <td style={{ border: "1px solid #dddddd", textAlign: "center", width: "10%" }}>{index + 1}</td>
          <td style={{ border: "1px solid #dddddd", width: "60%" }}>
            <b>Model:</b> {row.model_name} (Buyback)
            <br />
            <b>IMEI:</b> {row.imei}
          </td>
          <td style={{ border: "1px solid #dddddd", textAlign: "center", width: "10%" }}>{1}</td>
          <td style={{ border: "1px solid #dddddd", textAlign: "center" }}>- {row.price}/-</td>
        </tr>
      ))}
    </tbody>
  </table>
)}


          
            <table style={{height:"8%"}}>
              <tbody >
                <div style={{ float: "left",width:"70%",borderRight:"1px solid #dddddd",height:"100%"}}>
                  <div style={{ float: "left",width:"14.4%",borderRight:"1px solid #dddddd",height:"100%" }}>
                    
                  </div>
                  <div style={{height:"100%" ,marginLeft:"40%"}}>
                  <tr>
      <td>
        <input type="checkbox" id="checkbox1" defaultChecked />
        <label htmlFor="checkbox1">Handset</label>
      </td>
      <td>
        <input type="checkbox" id="checkbox2"defaultChecked />
        <label htmlFor="checkbox2">Charger</label>
      </td>
    </tr>
    <tr>
      <td>
        <input type="checkbox" id="checkbox3" defaultChecked />
        <label htmlFor="checkbox3">Box</label>
      </td>
      <td>
        <input type="checkbox" id="checkbox4" />
        <label htmlFor="checkbox4">Earphone</label>
      </td>
    </tr>
                  </div>
                </div>
                <div style={{ float: "right",width:"30%",height:"100%"  }}>
                <div style={{ float: "left",width:"33.4%",borderRight:"1px solid #dddddd",height:"100%" }}>
                    
                  </div>
                  <div style={{ float: "right" }}>

                  </div>
                </div>
                
              </tbody>
            </table>

         
          <div style={{ height: "50%" }}>

            <div className='row' style={{ padding: "0px 11px 11px 11px " }}>
              <div className='col col-md-8' style={{ borderRight: "1px solid #dddddd", borderTop: "1px solid #dddddd", borderBottom: "1px solid #dddddd",  }}>
                <p className='mt-1'><b >टीप - कोणत्याही सेकेंड मोबाइल वॉरंटी येत नाही.</b></p>
              </div>
              <div className='col col-md-4' style={{ borderRight: "1px solid #dddddd", borderBottom: "1px solid #dddddd" }}>
                <div className='row' style={{ borderRight: "1px solid" }}>
                  <div className='col col-md-6' style={{ border: "1px solid #dddddd" }}><p className='mt-1'>Sub Total:</p></div>
                  <div className='col col-md-6' style={{ border: "1px solid #dddddd" }}><p className='mt-1'>&#8377;{subTotal}/-</p></div>
                </div>
                <div className='row' style={{ borderRight: "1px solid" }}>
                  <div className='col col-md-6 ' style={{ border: "1px solid #dddddd" }}><p className='mt-1'>Discount:</p></div>
                  <div className='col col-md-6 ' style={{ border: "1px solid #dddddd" }}><p className='mt-1'>&#8377;{discount}/-</p></div>
                </div>
                <div className='row' style={{ borderRight: "1px solid" }}>
                  <div className='col col-md-6 ' style={{ border: "1px solid #dddddd" }}><p className='mt-1'>Grand Total:</p></div>
                  <div className='col col-md-6 ' style={{ border: "1px solid #dddddd" }}><p className='mt-1'>&#8377;{grandTotal}/-</p></div>
                </div>
              </div>
            </div>

            <table>
              <tr >

                <div className="text-center" style={{ height: "122px", padding: "0px 10px", width: "35%", float: "left", paddingTop: "5px" }}>
                  <img src="https://png.pngtree.com/png-clipart/20220602/original/pngtree-satisfaction-guarantee-badges-png-image_7885136.png" height={130} width={130} style={{ mixBlendMode: "multiply" }} />
                </div>
                <div style={{ height: "122px", width: "58%", float: "right" }}>
                  <div style={{ height: "122px", padding: "0px 10px", width: "50%", float: "left", paddingTop: "15px" }}>
                    <img src="https://img.freepik.com/premium-vector/purple-gradiend-social-media-logo_197792-1883.jpg" height={80} width={80} style={{ mixBlendMode: "multiply", marginLeft: "27px" }} />
                    <br /> <b >@Mobilecitygadhinglaj</b>
                  </div>
                  <div className="text-center" style={{ height: "122px", width: "50%", float: "right", paddingTop: "70px" }}>
                    <b>Mobile Ciy</b>
                  </div>
                </div>

              </tr>
            </table>
          </div>

          {/* <table style={{marginTop:"14%"}} >
          
            <tr >
              <th style={{width:"50%"}}> टीप - कोणत्याही सेकेंड मोबाइल वॉरंटी येत नाही.</th>
              <th></th>
              <th style={{width:"20%"}}>Total Amount:</th>
              <th>1000</th>
            </tr>

            <tr>
              <th style={{width:"20%"}}>Customer Sign</th>
              <th></th>
              <th><span>Signature</span></th>
              <th style={{width:"20%"}}>
              
              </th>
            </tr>
          </table> */}
        </div>
      </section>
        )}
    </>


  );
}


