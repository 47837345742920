import React, { useEffect, useState } from 'react'
import Config from '../Header/Config';
import Header from '../Header/Header';
import Footer from '../Header/Footer';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Purchase_payable_update() {
          const [date, setDate] = useState('');
          const [supplierName, setSupplierName] = useState("");
          const [mobileNumber, setMobileNumber] = useState("");
          const [address, setAddress] = useState("");
                  
        
                //..............Billing Details
        
        const [pendingAmount,setPendingAmount] = useState("");
        const [paidAmount, setPaidAmount] = useState("");
        const [paymentMode, setPaymentMode] = useState("");
        const[tax ,setTax] = useState("");
        
        const [availableBalance, setAvailableBalance]=useState("");
        
        const apiUrl=Config.apiUrl

        const navigate = useNavigate();
   
        const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const _id = searchParams.get("id");
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              if (_id) {
                const response = await fetch(`${apiUrl}/searchSinglePayable/${_id}`);
                const data = await response.json();
                console.log(data);
                setDate(data.data[0].date);
            setSupplierName(data.data[0].supplier_name);
                setMobileNumber(data.data[0].mobile_no);
                setAddress(data.data[0].address);
                setPendingAmount(data.data[0].pending_amount);
                setPaymentMode(data.data[0].payment_mode);
                setPaidAmount(data.data[0].paid_amount);
                setAvailableBalance(data.data[0].available_balance);
                setTax(data.data[0].trx_no);
              }
            } catch (error) {
              console.error(error);
            }
          };
      
          fetchData();
        }, [_id]);

        //.................................................................[updates]................................

        const [updatedDetails, setUpdatedDetails] = useState([]);
        const updatePurchasePayableDetails = async () => {
          const item = {
                    date:date,
                    supplier_name:supplierName,
                    mobile_no:mobileNumber,
                    address:address,
                    pending_amount:pendingAmount,
                    paid_amount:paidAmount,
                    available_balance:availableBalance,
                    payment_mode:paymentMode,
                    trx_no:tax
           
          };
      
          try {
            const result = await fetch(`${apiUrl}/update_purchasePayable/${_id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify(item),
            });
      
            if (result.ok) {
              alert("Purchase Payable updated successfully");
      
              setUpdatedDetails(updatedDetails);
      
              setSupplierName('')
              setMobileNumber('')
              setAddress('')
              setPendingAmount('')
              setPaidAmount('')
              setTax('')
              setPaymentMode('')
              setPaymentMode('')
              setAvailableBalance('')
              setDate('')
      
              navigate("/purchase_payable_table");
            } else {
          alert("Failed to update Purchase Payable");
            }
          } catch (error) {
            console.log(error);
            alert("Failed to update Purchase Payable");
          }
        };
  return (
          <>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
           
              <Header />
             
              <div className="layout-page">
               
                <div className="content-wrapper">
         {/* ........................................................................................................[Purchase Details].........................       */}
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="fw-bold py-3 mb-4">Purchase Payable</h4>
                 
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Supplier Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                         
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-company"
                            >
                              Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="basic-default-date"
                              placeholder="Enter date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
    
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-email"
                            >
                              Supplier Name
                            </label>
                            <div className="input-group input-group-merge">
                              <input
                                type="text"
                                id="basic-default-email"
                                className="form-control"
                                placeholder="Enter Supplier Name"
                                aria-label="john.doe"
                                aria-describedby="basic-default-email2"
                                value={supplierName}
                                onChange={(e) => setSupplierName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Mobile Number"
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                              maxLength={10}
                            />
                          </div>
    
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Address
                            </label>
                            <textarea
                              id="basic-default-message"
                              className="form-control"
                              placeholder="Enter Address"
                              defaultValue={""}
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              rows={1}
                            />
                          </div>
        {/* ....................................................................................................................................  */}
                          <hr className="mb-3 mt-3" />
          {/* ................................................................................................[Billing Details]........................................  */}
                       
                 <h5 className="mb-4">Billing Details</h5>
                          
                 <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Pending Amount
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Pending Amount"
                              value={pendingAmount}
                              onChange={(e) => setPendingAmount(e.target.value)}
                             
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Paid Amount
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Paid Amount"
                              value={paidAmount}
                              onChange={(e) => setPaidAmount(e.target.value)}
                             
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Available Balance
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Available Balance"
                              value={availableBalance}
                              onChange={(e) => setAvailableBalance(e.target.value)}
                             
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payment Mode
                        </label>
                        <select
                          className="form-select"
                          id="basic-default-company"
                          defaultValue=""
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Payment Mode
                          </option>
                          <option value="Cash">Cash</option>
                          <option value="Credit Card">Credit Card</option>
                          <option value="Debit Card">Debit Card</option>
                        
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Tax No
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Tax No"
                              value={tax}
                              onChange={(e) => setTax(e.target.value)}
                             
                            />
                          </div>
                          
                          <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          onClick={()=>updatePurchasePayableDetails()}
                        >
                          Update
                        </button>
                        <button type="button" className="btn btn-danger">
                          Cancel
                        </button>
                      </div>
     {/* .................................................................................................................................                        */}
                           
   
                          
                          
                        </div>
                      </div>
                    </div>
                   
                  </div>
                
                  <Footer />
                
                </div>
           
              </div>
            
            </div>
          </div>
        </>
  )
}
