import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const ITEMS_PER_PAGE = 10; // Number of items to display per page

export default function Model_table() {
  const apiUrl = Config.apiUrl;
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        if (tableData.length === 0) {
          const response = await fetch(`${apiUrl}/get_all_models`);
          const data = await response.json();
          console.log(data);
          setTableData(data.data.reverse()); // Reverse the data array before setting it
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Only fetch data if tableData is empty
    if (tableData.length === 0) {
      fetchTableData();
    }
  }, [apiUrl]); // Remove tableData from the dependency array

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (confirmDelete) {
      fetch(`${apiUrl}/delete_model/${id}`, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then(() => {
          toast.success("Record deleted successfully", { autoClose: 1500 });
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error deleting data:", error);
        });
    }
  };

  const handleUpdate = (id, event) => {
    navigate(`/model_update?id=${id}`);
  };
  //.....................................[ Search box ]...............................................
  const [searchQuery, setSearchQuery] = useState(""); // State variable for the search query
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };
  const filteredTableData = tableData.filter(
    (row) =>
      (row.model_name &&
        row.model_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.color_name &&
        row.color_name.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  //..................................[ Pagination ].........................................

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages based on the data and items per page
  const totalPages = Math.ceil(filteredTableData.length / ITEMS_PER_PAGE);

  // Calculate the index range for the items to be displayed on the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(
    startIndex + ITEMS_PER_PAGE,
    filteredTableData.length
  );

  // Function to handle pagination button clicks
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Model List</h4>

                <hr className="my-3" />
                {/* Search Box */}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div className="mb-3 col-md-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div className="card">
                  <h5 className="card-header">Model List</h5>
                  <div
                    className="table-responsive text-nowrap"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    {isLoading ? (
                      //spinner
                      <div className="d-flex justify-content-center mb-5">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only "></span>
                        </div>
                      </div>
                    ) : (
                      <table className="table mb-3">
                        <thead
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            backgroundColor: "#f5f5f5",
                          }}
                        >
                          <tr className="text-nowrap">
                            <th>SN</th>
                            <th>Model</th>
                            <th>Color</th>
                            <th>Opening Stock</th>
                            <th>Description</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(filteredTableData) &&
                          filteredTableData.length > 0 ? (
                            filteredTableData
                              .slice(startIndex, endIndex)
                              .map((row, index) => (
                                <tr key={index}>
                                  <td>{startIndex + index + 1}</td>
                                  <td>{row.model_name}</td>
                                  <td>{row.color_name}</td>
                                  <td>{row.opening_stock}</td>
                                  <td>{row.description}</td>
                                  <td>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      onClick={() => handleUpdate(row.id)}
                                    >
                                      <FaEdit />
                                    </button>
                                    <span className="ml-2"> </span>
                                    <button
                                      type="submit"
                                      className="btn btn-danger"
                                      onClick={() => handleDelete(row.id)}
                                    >
                                      <FaTrashAlt />
                                    </button>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="6">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {/* Pagination */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "10px 10px",
                  }}
                >
                  <nav aria-label="Page navigation">
                    <ul className="pagination">
                      {/* Previous arrow button */}
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          {"<"}
                        </button>
                      </li>

                      {/* Page numbers */}
                      {Array.from(
                        { length: totalPages },
                        (_, index) => index + 1
                      ).map(
                        (pageNumber) =>
                          // Render the page number button only if it's within the range of visible pages
                          pageNumber >= currentPage - 2 &&
                          pageNumber <= currentPage + 2 && (
                            <li
                              key={pageNumber}
                              className={`page-item ${
                                pageNumber === currentPage ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          )
                      )}

                      {/* Next arrow button */}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          {">"}
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <Footer />

              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}
