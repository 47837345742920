import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import { FaEdit, FaEye, FaEyeSlash, FaShoppingCart, FaTrashAlt } from "react-icons/fa";
import Config from "../Header/Config";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
export default function Users() {
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [tableData, setTableData] = useState([]);
  const navigate=useNavigate();

  const [showPassword, setShowPassword] = useState("");
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  //.........................................[ Add User ]...........................................
 
  const apiUrl = Config.apiUrl;

  const addUser = async (e) => {
    e.preventDefault();
    let item = {
      full_name: name,
      gender: gender,
      contact: mobileNumber,
      email: email,
      password: password,
    };
    try {
      let result = await fetch(`${apiUrl}/createUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });
  
      if (result.ok) {
        toast.success("User added successfully",{autoClose:1500});
        setName("");
        setGender("");
        setEmail("");
        setMobileNumber("");
        setPassword("");
  
        // Fetch the updated user list after successfully adding the new user
        fetchData(); // Assuming fetchData is the function that fetches the user list
      } else {
        toast.error("Failed to add User");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add User");
    }
  };
  
  //.......................................[ Fetch Users in Table]......................................
  
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/searchUsers`);
      const data = await response.json();
      console.log(data); // Add this line to check the data response
      setTableData(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
//....................................[ Delete User ]................................................

const handleDelete = (id) => {
  const confirmDelete = window.confirm(
    "Are you sure you want to delete this record?"
  );

  if (confirmDelete) {
    fetch(`${apiUrl}/deleteUser/${id}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  }
};
//................................[ clear feilds ]..............................................

const clearUserFeilds = () => {
  setName("");
  setGender("");
  setEmail("");
  setMobileNumber("");
  setPassword("");
}
//...........................................................................................

  const handleUpdate = (id, event) => {
    navigate(`/user_update?id=${id}`);
  };
  return (
    <>
    <div >
      <ToastContainer />
      </div>
    <div className="layout-wrapper layout-content-navbar">
      
      
      <div className="layout-container">
        <Header />

        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">User Profile</h4>

              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">User Profile</h5>
                </div>
                <form action="" onSubmit={addUser}>
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-email"
                          >
                            Name
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              type="text"
                              id="basic-default-email"
                              className="form-control"
                              placeholder="Enter Name"
                              aria-label="john.doe"
                              aria-describedby="basic-default-email2"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-email"
                          >
                            Gender
                          </label>
                          <div className="flex">
                            <div
                              className="form-check"
                              style={{
                                display: "inline-flex",
                                marginRight: "20px",
                              }}
                            >
                              <input
                                type="radio"
                                className="form-check-input"
                                id="maleOption"
                                name="genderOption"
                                value="Male" // Set different values for the radio buttons
                                checked={gender === "Male"} // Check if gender is "Male"
                                onChange={(e) => setGender(e.target.value)} // Update the gender state on change
                              />
                              <label
                                style={{ marginLeft: "7px" }}
                                className="form-check-label"
                                htmlFor="maleOption"
                              >
                                Male
                              </label>
                            </div>
                            <div
                              className="form-check"
                              style={{
                                display: "inline-flex",
                                marginRight: "7px",
                              }}
                            >
                              <input
                                type="radio"
                                className="form-check-input"
                                id="femaleOption"
                                name="genderOption"
                                value="Female" // Set different values for the radio buttons
                                checked={gender === "Female"} // Check if gender is "Female"
                                onChange={(e) => setGender(e.target.value)} // Update the gender state on change
                              />
                              <label
                                className="form-check-label"
                                htmlFor="femaleOption"
                                style={{ marginLeft: "7px" }}
                              >
                                Female
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-phone"
                          >
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            id="basic-default-phone"
                            className="form-control phone-mask"
                            placeholder="Enter Mobile Number"
                            maxLength={10}
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-phone"
                          >
                            Email ID
                          </label>
                          <input
                            type="email"
                            id="basic-default-message"
                            className="form-control"
                            placeholder="Enter Email ID"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-message"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="basic-default-message"
                              className="form-control"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                            <span
                              className="input-group-text"
                              onClick={handleTogglePassword}
                            >
                              {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="col-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                            >
                              Add
                            </button>

                            <button type="button" className="btn btn-danger"
                            onClick={clearUserFeilds}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>  
                       
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card mb-4">
              {tableData.length > 0 ? (
                          <div className="card mt-3">
                            <h5 className="card-header">User List</h5>
                            <div className="table-responsive text-nowrap">
                              <table className="table">
                                <thead
                                 style={{
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1,
                                  backgroundColor: "#f5f5f5",
                                }}>
                                  <tr className="text-nowrap">
                                    <th>SN</th>
                                    <th> Name</th>
                                    <th>Gender</th>
                                    <th>Contact</th>
                                    <th>Email</th>
                                    
                                    <th>Password</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData
                                  .slice()
                                  .reverse()
                                  .map((row, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{row.full_name}</td>
                                      <td>{row.gender}</td>
                                      
                                      <td>{row.contact}</td>
                                      <td>{row.email}</td>
                                      <td>{row.password}</td>
                                      <td>
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          onClick={() => handleDelete(row.id)}
                                        >
                                          <FaTrashAlt />
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                         style={{marginLeft:"5px"}}
                                         onClick={() => handleUpdate(row.id)}
                                        >
                                          <FaEdit />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div className=""></div>
                        )}
              </div>
            </div>

            <Footer />

            <div className="content-backdrop fade" />
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle" />
    </div>
    </>
  );
}
