import React, { useEffect, useState } from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'
import { FaEdit, FaShoppingCart, FaTrashAlt } from 'react-icons/fa';
import Config from '../Header/Config';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Sale_update() {
  const [imeiNotFound, setImeiNotFound] = useState(false);
  //............set  todays date

  const today = new Date().toISOString().split("T")[0];
  //............Customer Details
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState(today);
  const [customerName, setCustomerName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");

  //............Buy Back Details

  const [buyBackIemi, setBuyBacIemi] = useState("");
  const [buyBackModel, setBuyBackModel] = useState("");
  const [buyBackPrice, setBuyBackPrice] = useState("");

  //..............Brand Details

  const [modelName, setModelName] = useState("");
  const [imei, setImei] = useState("");
  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [accessories, setAccessories] = useState("Charger, Bill Box");

  //................Payment Details
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState("");
  let [grantTotal, setGrantTotal] = useState(0);
  const [payableAmount, setPayableAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

 

  const apiUrl = Config.apiUrl;
  const navigate = useNavigate();

  const location = useLocation();
  
const searchParams = new URLSearchParams(location.search);
const invoice_Number = searchParams.get("invoice_no");

const imeiParam = searchParams.get("id");
const idParam = parseInt(imeiParam);

const [selectedRow, setSelectedRow] = useState(null); // New state to store the selected row
const [totalPurchasePrice, setTotalPurchasePrice] = useState(0); // New state for total purchase prices

const handleBrandUpdate = async (row) => {
  const { id } = row;
  setSelectedRow(row);
  const queryParams = new URLSearchParams({
    invoice_no: invoice_Number,
    id: id, // Make sure to pass the correct 'id' value here
  });
  navigate(`/sale_update?${queryParams}`);
};

//................................[ Sales Details ]....................................................
//........................................[ set data in brand details].........................................
const [tableData, setTableData] = useState([]);
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/get_SalesInvoice/${invoice_Number}`);

      const data = await response.json();
       const combineData=data.data
      console.log(combineData);

      setInvoiceNumber(data.data[0].invoice_no);
      setDate(data.data[0].date);
      setCustomerName(data.data[0].customer_name);
      setMobileNumber(data.data[0].mobile_no);
      setAddress(data.data[0].address);
     
      setPayableAmount(data.data[0].payable_amount);
       setDiscount(data.data[0].discount);
       setGrantTotal(data.data[0].grand_total);
      setPaymentMode(data.data[0].payment_mode);
      setSubTotal(data.data[0].sub_total)
      setTableData(combineData);


      const brandDetails = data.data.filter((item) => item.id === idParam);

      
        setModelName(brandDetails[0].model_name);
        setColor(brandDetails[0].color);
        setImei(brandDetails[0].imei);
        setPrice(brandDetails[0].price);
        setAccessories(brandDetails[0].accessories);
   

      

      
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, [invoice_Number,idParam]);



//...................................................[ Update brand Items].....................................................................................
      
    
const updateBrandDetails = async (e, id, invoice_no) => {
  const item = {
    model_name: modelName,
    color: color,
    imei: imei,
    price: price,
    accessories: accessories,
  };

  try {
    const result = await fetch(`${apiUrl}/update_salesItems/${idParam}/${invoice_Number}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    if (result.ok) {
      alert("Sales Details updated successfully");

      // Find the index of the updated item in the tableData array
      const updatedIndex = tableData.findIndex((item) => item.id === idParam);

      if (updatedIndex !== -1) {
        // Update the item in the tableData array
        const updatedTableData = [...tableData];
        updatedTableData[updatedIndex] = item;
        setTableData(updatedTableData);

        // Calculate the new subTotal based on the updated tableData
        const newSubTotal = updatedTableData.reduce((total, item) => Number(total) + Number(item.price), 0);
       
        setSubTotal(newSubTotal) // Set the new subTotal value
      

      setModelName("");
      setImei("");
      setAccessories("");
      setPrice("");
      setColor("");
      
    } else {
      alert("Failed to update Sales Details");
    }
  }} catch (error) {
    console.log(error);
    alert("Failed to update Sales Details");
  }
};
//...........................[ Update Sale Details ].........................................................
const [showBrandListTable, setShowBrandListTable] = useState(true); // Default: show the table
const[updatedDetails,setUpdatedDetails]=useState([])
const updateSaleDetails = async () => {
  const item = {
    date:date,
    customer_name:customerName,
    mobile_no:mobileNumber,
    address:address,
    sub_total:subTotal,
    discount:discount,
    grand_total:grantTotal,
    payable_amount:payableAmount,
    payment_mode:paymentMode
    
  };

  try {
    const result = await fetch(`${apiUrl}/update_salesDetails/${invoice_Number}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    if (result.ok) {
      alert("Sales Details updated successfully");
      setShowBrandListTable(false);
      setUpdatedDetails(updatedDetails);

      setInvoiceNumber("");
      setDate("");
      setCustomerName("");
      setMobileNumber("");
      setAddress("");
      setSubTotal("");
      setDiscount('');
      setGrantTotal("");
      setPayableAmount('');
      
      setPaymentMode("");
      setModelName('')
      setImei('')
      setAccessories('')
      setPrice('')
      setColor('')
  
    } else {
      alert("Failed to update Sales Details");
    }
  } catch (error) {
    console.log(error);
    alert("Failed to update Sales Details");
  }
};

//........................................................................................

// Delete Record from database
const handleDelete = (id) => {
  const confirmDelete = window.confirm(
    "Are you sure you want to delete this record?"
  );

  if (confirmDelete) {
    fetch(`${apiUrl}/delete_salesItems/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        const deleteItem = tableData.find((item) => item.id === id);
        const newSubTotal = Number(subTotal) - Number(deleteItem.price);
        setSubTotal(newSubTotal);
        setTableData((preData) => preData.filter((brand) => brand.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        // You can log the error details or show an error message to the user
      });
  }
};
//...................................


const [modelList, setModelList] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/get_all_models`);
      const data = await response.json();
      setModelList(data.data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);
     

  return (
    <>
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Header />

        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">Sale Details</h4>

              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Customer Details</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-fullname"
                      >
                        Invoice Number
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Enter Invoice Number"
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="basic-default-fullname"
                        placeholder="Enter date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-email"
                      >
                        Customer Name
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          id="basic-default-email"
                          className="form-control"
                          placeholder="Enter Customer Name"
                          aria-label="john.doe"
                          aria-describedby="basic-default-email2"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Mobile Number"
                        maxLength={10}
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Address
                      </label>
                      <textarea
                        id="basic-default-message"
                        className="form-control"
                        placeholder="Enter Address"
                        defaultValue={""}
                        rows={1}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <hr className="mb-3 mt-3" />
                    <h5 className="mb-4">Buy Back Details</h5>

                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company"
                      >
                        Model Name
                      </label>
                      <input
                        type="text"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Model Name"
                        value={buyBackModel}
                        onChange={(e) => setBuyBackModel(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        IMEI
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter IMEI"
                        value={buyBackIemi}
                        onChange={(e) => setBuyBacIemi(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Price
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Price"
                        value={buyBackPrice}
                        onChange={(e) => setBuyBackPrice(e.target.value)}
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-end mt-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                      
                      >
                        Buy Back
                      </button>
                    </div>
                    <hr className="mb-3 mt-3" />

                    <h5 className="mb-4">Brand Details</h5>

                    <div className="col-md-6 mb-3">
                    <div className="row">
                        <div className="col-md-6 mb-3">
  <label className="form-label" htmlFor="basic-default-company">
    Model Name
  </label>
  <input
    type="text"
    className="form-control"
    list="browsers"
    name="browser"
    id="browser"
    placeholder="Enter Model Name"
    value={modelName}
    onChange={(e) => setModelName(e.target.value)}
  />
  <datalist id="browsers">
    {modelList.map((model, index) => (
      <option key={index} value={model.model_name} />
    ))}
  </datalist>
</div>
<div className="col-md-6 mb-3">
  <label className="form-label" htmlFor="basic-default-company">
    Color
  </label>
  <input
    type="text"
    className="form-control"
    id="basic-default-company"
    placeholder="Enter Color"
    value={color}
    onChange={(e) => setColor(e.target.value)}
  />
</div>

                        
                        </div>
                      </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        IMEI
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter IMEI"
                        value={imei}
                        onChange={(e) =>
                          setImei(e.target.value, (e.target.value))
                        }
                      />
                      {imeiNotFound && <span style={{color:"red"}}>IMEI not found</span>}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Price
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Accessories
                      </label>
                      <textarea
                        id="basic-default-message"
                        className="form-control"
                        placeholder="Enter description"
                        rows={1}
                        value={accessories}
                        onChange={(e) => setAccessories(e.target.value)}
                      />
                    </div>

                    <div className="col-12">
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          onClick={()=>updateBrandDetails()}
                        >
                          Update
                        </button>
                      </div>
                      {showBrandListTable && (
                        <div className="card mt-3">
                          <h5 className="card-header">Brand List</h5>

                          <div className="table-responsive text-nowrap">
                            <table className="table">
                              <thead>
                                <tr className="text-nowrap">
                                  <th>SN</th>

                                  <th>Model Name</th>
                                  <th>Color</th>
                                  <th>IMEI</th>
                                  <th> Price</th>

                                  <th>Accessories</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(tableData) &&
                                  tableData.map((row, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>

                                      <td>{row.model_name}</td>
                                      <td>{row.color}</td>
                                      <td>{row.imei}</td>
                                      <td>{row.price}</td>

                                      <td>{row.accessories}</td>
                                      <td>
                                      <button
                                          type="submit"
                                          className="btn btn-primary"
                                          onClick={() => handleBrandUpdate(row)}
                                        style={{marginRight:"5px"}}>
                                                  
                                          <FaEdit/>
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          onClick={() => handleDelete(row.id)}
                                        >
                                          <FaTrashAlt />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                     
                      )}
                    </div>
                    <hr className="mb-3 mt-3" />
                    <h5 className="mb-4">Payment Details</h5>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company"
                      >
                        Sub Total
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Sub Total"
                        value={subTotal}
                        onChange={(e) => setSubTotal(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-company"
                      >
                        Discount
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Discount"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Grant Total
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Grant Total"
                        value={grantTotal=subTotal-discount}
                        onChange={(e) => setGrantTotal(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Payable Amount
                      </label>
                      <input
                        type="number"
                        id="basic-default-phone"
                        className="form-control phone-mask"
                        placeholder="Enter Payable Amount
                              "
                        value={payableAmount}
                        onChange={(e) => setPayableAmount(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-phone"
                      >
                        Payment Mode
                      </label>
                      <select
                        className="form-select"
                        id="basic-default-company"
                        defaultValue=""
                        value={paymentMode}
                        onChange={(e) => setPaymentMode(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Payment Mode
                        </option>
                        <option value="ACME Inc.">GPAY</option>
                        <option value="Example Corp">PHONEPAY</option>
                      </select>
                    </div>

                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                        onClick={()=>updateSaleDetails()}
                      >
                        Update
                      </button>

                      <button type="submit" className="btn btn-danger">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer/>

            <div className="content-backdrop fade" />
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle" />
    </div>
  </>
  )
}
