import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Model from "./Pages/Componant/Model";
import Purchase from "./Pages/Componant/Purchase";
import Sale from "./Pages/Componant/Sale";
import Model_table from "./Pages/Componant/Model_table";
import Purchase_table from "./Pages/Componant/Purchase_table";
import Sale_table from "./Pages/Componant/Sale_table";
import Sale_BuyBack_Table from "./Pages/Componant/Sale_BuyBack_Table";
import Invoice from "./Pages/Componant/Invoice";
import Purchase_invoice from "./Pages/Componant/Purchase_invoice";
import Leager_customer from "./Pages/Componant/Leager_customer";
import Purchase_payable from "./Pages/Componant/Purchase_payable";
import Sale_payable from "./Pages/Componant/Sale_payable";
import Dailybook from "./Pages/Componant/Dailybook";
import Model_update from "./Pages/Componant/Model_update";
import Purachse_update from "./Pages/Componant/Purachse_update";
import Sale_update from "./Pages/Componant/Sale_update";
import Users from "./Pages/Componant/Users";
import Login from "./Pages/Header/Login";
import Dailybook_table from "./Pages/Componant/Dailybook_table";
import User_udate from "./Pages/Componant/User_udate";
import Dailybook_update from "./Pages/Componant/Dailybook_update";
import { AuthProvider } from "./Pages/Header/AuthContext";
import Leager_customer_table from "./Pages/Componant/Leager_customer_table";
import Leager_suppiler_table from "./Pages/Componant/Leager_suppiler_table";
import Leager_suppiler from "./Pages/Componant/Leager_suppiler";
import Purchase_payable_table from "./Pages/Componant/Purchase_payable_table";
import Purchase_payable_update from "./Pages/Componant/Purchase_payable_update";
import Purchase_report from "./Pages/Componant/Purchase_report";
import Sale_report from "./Pages/Componant/Sale_report";
import Dailybook_leager from "./Pages/Componant/Dailybook_leager";
import Dailybook_leager_table from "./Pages/Componant/Dailybook_leager_table";
import Leager_general_table from "./Pages/Componant/Leager_general_table";
import Leager_general from "./Pages/Componant/Leager_general";
import Customer_details from "./Pages/Componant/Customer_details";
import Customer_details_table from "./Pages/Componant/Customer_details_table";
import Customer_update from "./Pages/Componant/Customer_update";
import Page404 from "./Pages/Header/404/Page404";

function App() {

  const isLoggedIn=()=>{
    const loggedIn=localStorage.getItem('isLoggedIn');
    return loggedIn==='true';
  };

  const PrivateRoute = ({element})=>{
    return isLoggedIn() ? element : <Navigate to="/login" />;
  }

  
  return (
    <div className="App">
      
      <Router>
      <AuthProvider>
        <Routes>

          <Route path="/login" element={<Login/>} />
          <Route path="/" element={<PrivateRoute element={<Dashboard/>}/>} />
          <Route path="*" element={<PrivateRoute element={<Page404/>}/>} />
         

          <Route path="/model" element={<PrivateRoute element={<Model/>}/>} />
          <Route path="/model_table" element={<PrivateRoute element={<Model_table/>}/>} />
          <Route path="/model_update" element={<PrivateRoute element={<Model_update/>}/>} />

          <Route path="/suppiler" element={<PrivateRoute element={<Customer_details/>}/>} />
          <Route path="/suppiler_table" element={<PrivateRoute element={<Customer_details_table/>}/>} />
          <Route path="/suppiler_update" element={<PrivateRoute element={<Customer_update/>}/>} />

          <Route path="/purchase" element={<Purchase />} />
          <Route path="/purchase_table" element={<PrivateRoute element={<Purchase_table/>}/>} />
          <Route path="/purchase_update" element={<PrivateRoute element={<Purachse_update/>}/>} />

          <Route path="/purchase_invoice" element={<PrivateRoute element={<Purchase_invoice/>}/>} />


          <Route path="/purchase_payable" element={<PrivateRoute element={<Purchase_payable/>}/>} />
          <Route path="/purchase_payable_table" element={<PrivateRoute element={<Purchase_payable_table  />}/>} />
          <Route path="/purchase_payable_update" element={<PrivateRoute element={<Purchase_payable_update/>}/>} />

          <Route path="/purchase_report" element={<PrivateRoute element={<Purchase_report/>}/>} />


          <Route path="/sale" element={<PrivateRoute element={<Sale/>}/>} />
          <Route path="/sale_table" element={<PrivateRoute element={<Sale_table/>}/>} />
          <Route path="/sale_update" element={<PrivateRoute element={<Sale_update/>}/>} />
          <Route path="/sale_buyback_table" element={<PrivateRoute element={<Sale_BuyBack_Table/>}/>} />

          <Route path="/invoice_sale" element={<PrivateRoute element={<Invoice/>}/>} />
          <Route path="/sale_payable" element={<PrivateRoute element={<Sale_payable/>}/>} />
          <Route path="/sale_report" element={<PrivateRoute element={<Sale_report/>}/>} />


          <Route path="/dailybook" element={<PrivateRoute element={<Dailybook/>}/>} />
          <Route path="/dailybook_list" element={<PrivateRoute element={<Dailybook_table/>}/>} />
          <Route path="/dailybook_update" element={<PrivateRoute element={<Dailybook_update/>}/>} />
          <Route path="/dailybook_leager_list" element={<PrivateRoute element={<Dailybook_leager_table/>}/>} />
          <Route path="/dailybook_leager" element={<PrivateRoute element={<Dailybook_leager/>}/>} />



          <Route path="/suppiler_leager" element={<PrivateRoute element={<Leager_suppiler/>}/>} />
          <Route path="/supplier_leager_table" element={<PrivateRoute element={<Leager_suppiler_table/>}/>} />


          <Route path="/user" element={<PrivateRoute element={<Users/>}/>} />
          <Route path="/user_update" element={<PrivateRoute element={<User_udate/>}/>} />

          <Route path="/general_leager_table" element={<PrivateRoute element={<Leager_general_table/>}/>} />
          <Route path="/general_leager" element={<PrivateRoute element={<Leager_general/>}/>} />

        </Routes>
        </AuthProvider>
      </Router>
      

     
    </div>
  );
}

export default App;
