import React from 'react'
import './404_page.css'
export default function Page404() {
  return (
          <div class="the-width">
          <div class="">
                    <div class="four04">
                              <h1>404</h1>
                              <div class="target-cont">
                                        <div class="cover"></div>
                                        <div class="marks"></div>
                              </div>
                    </div>	
                    <h2>The page you requested was not found.</h2>
</div>
</div>
  )
}
