import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { useNavigate } from "react-router-dom";

import { FaPrint } from "react-icons/fa";
const ITEMS_PER_PAGE = 10 ; // Number of items to display per page

export default function Sale_report() {
  const today = new Date().toISOString().split("T")[0];
  

  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const [tableData, setTableData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = Config.apiUrl;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const purchaseDetailsResponse = await fetch(
          `${apiUrl}/getSalesReport/${fromDate}/${toDate}`
        );
        const purchaseDetailsData = await purchaseDetailsResponse.json();

        setTableData(purchaseDetailsData.data.reverse());
        console.log(purchaseDetailsData)
        setIsLoading(false);
       
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fromDate, toDate]);
  //..................................[ Pagination ].........................................

  
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages based on the data and items per page
  const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);

  // Calculate the index range for the items to be displayed on the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, tableData.length);

 // Function to handle pagination button clicks
const handlePageChange = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= totalPages) {
    setCurrentPage(pageNumber);
  }
};
  return (
          <>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <Header />
    
              <div className="layout-page">
                <div className="content-wrapper">
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="fw-bold py-3 mb-4">Sales Report</h4>
    
                    <hr className="my-5" />
                    
                     <div className="row">
                      <div className="col-md-6">
                      </div>
                      <div className="col-md-6">
                    <div className="row align-items-end mb-3">
                          <div className="col-md-2"></div>
                          <div className="form-group col-md-4 mb-0">
                            <label className="form-label" htmlFor="start-date">
                              From
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="start-date"
                              value={fromDate}
  onChange={(e) => setFromDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-md-4 mb-0">
                            <label className="form-label" htmlFor="end-date">
                              To
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="end-date"
                              value={toDate}
                              onChange={(e) => setToDate(e.target.value)}
                            />
                          </div>
                          </div>
                        </div>
                        </div>
                    <div className="card">
                      <h5 className="card-header"> Sales Report </h5>
                      <div className="table-responsive text-nowrap"
                       style={{ overflowY: "auto", maxHeight: "600px" }}
                      >
                         {isLoading ? (
           
           <div className="d-flex justify-content-center mb-5">
           <div className="spinner-border text-primary" role="status">
             <span className="sr-only "></span>
           </div>
         </div>
            
          ) : (
                        <table className="table">
                          <thead
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            backgroundColor: "#f5f5f5",
                          }}
                          >
                            <tr className="text-nowrap">
                              <th>SN</th>
                              <th>INVOICE NUMBER</th>
                              
                              <th>Model NAME</th>
                              <th>Color</th>
                              <th>IMEI</th>
                              <th>PRICE</th>
                              <th>accessories</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {Array.isArray(tableData) && tableData.length > 0 ? (
    tableData.slice(startIndex, endIndex).map((row, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{row.invoice_no}</td>
                                 
                                  <td>{row.model_name}</td>
                                  <td>{row.color}</td>
                                  <td>{row.imei}</td>
                                  <td>{row.price}</td>
                                  <td>{row.accessories}</td>
                                  
                                  <td>
                                    <button
                                      type="submit"
                                      className="btn btn-danger"
                                      style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                      }}
                                      
                                    >
                                      
                                      <FaPrint />
                                    </button>
                                    
                                  </td>
                                </tr>
                               ))
                               ) : (
                                 <tr>
                                   <td colSpan="6">No data available</td>
                                 </tr>
                               )}
                          </tbody>
                        </table>
                           )}
                      </div>
                    </div>
                      {/* Pagination */}
    <div style={{ display: "flex", justifyContent: "end", padding: "10px 10px" }}>
      <nav aria-label="Page navigation">
        <ul className="pagination">
          {/* Previous arrow button */}
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
          </li>
    
          {/* Page numbers */}
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
            // Render the page number button only if it's within the range of visible pages
            (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2) && (
              <li
                key={pageNumber}
                className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            )
          ))}
    
          {/* Next arrow button */}
          <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
          </li>
        </ul>
      </nav>
    </div>
                  </div>
    
                  <Footer />
    
                  <div className="content-backdrop fade" />
                </div>
              </div>
            </div>
    
            <div className="layout-overlay layout-menu-toggle" />
          </div>
        </>
  )
}
