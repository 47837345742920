import React, { useState } from 'react';

import Footer from '../Header/Footer'
import Header from '../Header/Header'
import Config from '../Header/Config';
import { ToastContainer, toast } from 'react-toastify';

export default function Customer_details() {

  
  const [fullName, setFullName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [address, setAddress] = useState('');

  const apiUrl= Config.apiUrl


 const  addCustomer = async (e)=>{

  e.preventDefault();
  let item={
    supplier_name:fullName,
    contact:contactNumber,
    address:address
  };
  try {

    let result=await fetch(`${apiUrl}/save_supplier`,{
      method: 'POST',
      headers:{
        "Content-Type": "application/json",
        Accept:"application/json",
      },
      body: JSON.stringify(item),
    });

    if(result.ok){
      toast.success("Supplier added successfully",{ autoClose: 1500 });
      setFullName('')
      setContactNumber('')
      setAddress('')

    }else{
      toast.error("failed to add Supplier",{ autoClose: 1500 });
    }
    
  } catch (error) {
    console.log(error);
    toast.error("Failed to add Supplier",{ autoClose: 1500 });
    
  }

 }


const   cancelFields=()=>{
       
    setFullName('')
    setContactNumber('')
    setAddress('')
 }

  
  return (
          <>
          <ToastContainer/>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              {/* Menu */}
              <Header />
              {/* / Menu */}
              {/* Layout container */}
              <div className="layout-page">
                {/* Navbar */}
               
                {/* / Navbar */}
                {/* Content wrapper */}
                <div className="content-wrapper">
                  {/* Content */}
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="fw-bold py-3 mb-4">Supplier Details</h4>
                    {/* Basic Layout */}
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Supplier Details</h5>
                        
                      </div>
                      <div className="card-body">
                        <form onSubmit={addCustomer}>
                          <div className="row">
                            
                            <div className="col-md-6 mb-3">
                              <label className="form-label" htmlFor="basic-default-fullname">
                              Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="basic-default-fullname"
                                placeholder="Enter Full Name"
                                value={fullName}
                                onChange={e => setFullName(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                             <label className="form-label" htmlFor="basic-default-company">
                             Contact Number
                             </label>
                             <input
                                type="text"
                                className="form-control"
                                id="basic-default-fullname"
                                placeholder="Enter Contact Name"
                                value={contactNumber}
                                onChange={e => setContactNumber(e.target.value)}
                                required
                                maxLength={10}
                              />
                                  </div>
    
                            <div className="col-md-6 mb-3">
                              <label className="form-label" htmlFor="basic-default-email">
                                Address
                              </label>
                              <div className="input-group input-group-merge">
                              <textarea
                                id="basic-default-message"
                                className="form-control"
                                placeholder="Enter Address"
                                defaultValue={""}
                                value={address}
                                onChange={e => setAddress(e.target.value)}
                                rows={1}
                                required
                              />
                                
                              </div>
                            
                            </div>
                           
                            <div className="col-12">
                            <div className="col-12 d-flex justify-content-end">
  <button type="submit" className="btn btn-primary"style={{marginLeft:"5px", marginRight:"5px"}}>
    Submit
  </button>
  
                              <button type='submit' className="btn btn-danger" onClick={()=>cancelFields()}>Cancel</button>
</div>

                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* / Content */}
                  {/* Footer */}
                  <Footer />
                  {/* / Footer */}
                  <div className="content-backdrop fade" />
                </div>
                {/* Content wrapper */}
              </div>
              {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
          </div>
          {/* / Layout wrapper */}
          
        </>
  )
}
