import React, { useState } from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'
import Config from '../Header/Config';
import { ToastContainer, toast } from 'react-toastify';

export default function Dailybook() {

           //............set today date

  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const[name , setName] = useState('');
  const [credit,setCredit] = useState('');
  const [debit,setDebit] = useState('');
  const [paymentMode,setPaymentMode] = useState('');
  const [note, setNote]=useState('');

  const apiUrl= Config.apiUrl
  //........................................................................


 const  AddDailyBook = async (e)=>{

  e.preventDefault();
  let item={
    entry_name:name,
date:date,
creadit_amount:credit,
debit_amount:debit,
payment_mode:paymentMode,
note:note

  };
  try {

    let result=await fetch(`${apiUrl}/createEntry`,{
      method: 'POST',
      headers:{
        "Content-Type": "application/json",
        Accept:"application/json",
      },
      body: JSON.stringify(item),
    });

    if(result.ok){
      toast.success("Dailybook added successfully",{autoClose:1500});
      setName('')
      setDebit('')
      setCredit('')
      setNote('')
      setPaymentMode('')

    }else{
      toast.error("failed to add Dailybook",{autoClose:1500});
    }
    
  } catch (error) {
    console.log(error);
    toast.error("Failed to add Dailybook",{autoClose:1500});
    
  }

 }


//-------------- {  Cancel Model  }----------------------

const cancelDailyBook = () => {
  setName('')
     setDebit('')
     setCredit('')
   setNote('')
     setPaymentMode('')
};

  return (
          
          <>
          <ToastContainer/>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
           
              <Header />
             
              <div className="layout-page">
               
                <div className="content-wrapper">
         {/* ........................................................................................................[Purchase Details].........................       */}
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="fw-bold py-3 mb-4">Daily Book Details</h4>
                 
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Daily Book Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-fullname"
                            >
                               Name Of Entry
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Name"
                              value={name}
                              onChange={(e)=>setName(e.target.value)}
                              
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-company"
                            >
                              Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="basic-default-date"
                              placeholder="Enter date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
    
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-email"
                            >
                              Credit Amount 
                            </label>
                            <div className="input-group input-group-merge">
                              <input
                                type="number"
                                id="basic-default-email"
                                className="form-control"
                                placeholder="Enter Amount"
                                aria-label="john.doe"
                                aria-describedby="basic-default-email2"
                               value={credit}
                               onChange={(e)=>setCredit(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Debit Amount
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Amount"
                              value={debit}
                              onChange={(e)=>setDebit(e.target.value)} 
                            />
                          </div>
    
                          <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payment Mode
                        </label>
                        <select
                          className="form-select"
                          id="basic-default-company"
                          defaultValue=""
                          value={paymentMode}
                          onChange={(e)=>setPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Payment Mode
                          </option>
                          <option value="Credit Card">Credit Card</option>
                          <option value="Debit Card">Debit Card</option>
                          <option value="Net Banking">Net Banking</option>
                          <option value="UPI">UPI</option>
                        </select>
                      </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Note
                            </label>
                            <input
                            type='text'
                              id="basic-default-message"
                              className="form-control"
                              placeholder="Enter Note"
                              value={note}
                              onChange={(e)=>setNote(e.target.value)}
                              
                            />
                          </div>
        {/* ....................................................................................................................................  */}
                         
         
                    
                      
                          <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          onClick={AddDailyBook}
                        >
                          Submit
                        </button>
                        <button type="button" className="btn btn-danger"
                        onClick={cancelDailyBook}>
                          Cancel
                        </button>
                      </div>
     {/* .................................................................................................................................                        */}
                           
   
                          
                          
                        </div>
                      </div>
                    </div>
                   
                  </div>
                
                  <Footer />
                
                </div>
           
              </div>
            
            </div>
          </div>
        </>
  )
}
