import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { useNavigate } from "react-router-dom";

import { FaEdit, FaEye, FaShoppingCart, FaTrashAlt, FaTrashRestoreAlt } from "react-icons/fa";
const ITEMS_PER_PAGE = 10 ; // Number of items to display per page
export default function Sale_table() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = Config.apiUrl;

  // .....................................................[ get All Sales table]....................................

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_salesDetails`);
        const data = await response.json();
        setTableData(data.data.reverse());
        setIsLoading(false);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
      finally{
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  //.....................................[ navigate towards invoice sale using invoice no]............................

  const navigate = useNavigate();
  const handleView = async (id, event) => {
    event.stopPropagation(); // Stop event propagation
    const invoice = id;
    navigate(`/invoice_sale?invoice_no=${invoice}`);
  };
  //.................................................................................................................
  const handleUpdate = async (id, event) => {

    const invoice = id;
    navigate(`/sale_update?invoice_no=${invoice}`);
  };
  //..................................[serach box]........................................................................

  const [searchQuery, setSearchQuery] = useState(""); // State variable for the search query
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1)
  };

  const filteredTableData = tableData.filter((row) =>
  row.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  row.date.toString().includes(searchQuery.toString()) ||
  row.invoice_no.toString().includes(searchQuery.toString()) ||
  row.grand_total.toString().includes(searchQuery.toString())
  
  );
  //..................................[ Pagination ].........................................

  
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages based on the data and items per page
  const totalPages = Math.ceil(filteredTableData.length / ITEMS_PER_PAGE);

  // Calculate the index range for the items to be displayed on the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredTableData.length);

 // Function to handle pagination button clicks
const handlePageChange = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= totalPages) {
    setCurrentPage(pageNumber);
   
  }
};
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Sales List</h4>

                <hr className="my-5" />
                 {/* Search Box */}
                 <div style={{ display: "flex", justifyContent: "end" }}>
                 <div className="mb-3 col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                    </div>
                <div className="card">
                  <h5 className="card-header"> Sales List </h5>
                  <div className="table-responsive text-nowrap"
                   style={{ overflowY: "auto", maxHeight: "600px" }}
                  >
                     {isLoading ? (
       
       <div className="d-flex justify-content-center mb-5">
       <div className="spinner-border text-primary" role="status">
         <span className="sr-only "></span>
       </div>
     </div>
        
      ) : (
                    <table className="table">
                      <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "#f5f5f5",
                      }}
                      >
                        <tr className="text-nowrap">
                      
                          <th>INVOICE NUMBER</th>
                          <th>DATE</th>
                          <th>CUSTOMER NAME</th>
                          <th>GRANT TOTAL</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                      {Array.isArray(filteredTableData) && filteredTableData.length>0 ? (
            filteredTableData.slice(startIndex, endIndex).map((row, index) => (
                            <tr key={index}>
                           
                              <td>{row.invoice_no}</td>
                              <td>{row.date}</td>
                              <td>{row.customer_name}</td>

                              <td>{row.grand_total}</td>
                              <td>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={(event) =>
                                    handleView(row.invoice_no, event)
                                  }
                                >
                                  
                                  <FaEye />
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={(event) =>
                                    handleUpdate(row.invoice_no, event)
                                  }
                                >
                                  <FaEdit/>
                                  </button>
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                >
                                  <FaTrashAlt />
                                </button>
                              </td>
                            </tr>
                          ))
                          ) : (
                            <tr>
                              <td colSpan="6">No data available</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                       )}
                  </div>
                </div>
                  {/* Pagination */}
<div style={{ display: "flex", justifyContent: "end", padding: "10px 10px" }}>
  <nav aria-label="Page navigation">
    <ul className="pagination">
      {/* Previous arrow button */}
      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
      </li>

      {/* Page numbers */}
      {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
        // Render the page number button only if it's within the range of visible pages
        (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2) && (
          <li
            key={pageNumber}
            className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        )
      ))}

      {/* Next arrow button */}
      <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </li>
    </ul>
  </nav>
</div>
              </div>

              <Footer />

              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}
