import React, { useState } from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'

export default function Sale_payable() {

             //............set today date

  const today = new Date().toISOString().split("T")[0];
  //..........Supplier Details
const [invoiceNumber, setInvoiceNumber] = useState("");
const [date, setDate] = useState(today);
const [customerName, setCustomerName] = useState("");
const [mobileNumber, setMobileNumber] = useState("");
const [address, setAddress] = useState("");
  

//..............Billing Details

const [pendingAmount,setPendingAmount] = useState("");
const [paidAmount, setPaidAmount] = useState("");
const [paymentMode, setPaymentMode] = useState("");
const[tax ,setTax] = useState("");
const [desc ,setDesc] = useState("");
const [availableBalance, setAvailableBalance]=useState("");
  return (
          <>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
           
              <Header />
             
              <div className="layout-page">
               
                <div className="content-wrapper">
         {/* ........................................................................................................[Purchase Details].........................       */}
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="fw-bold py-3 mb-4">Sale Payable</h4>
                 
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Customer Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-fullname"
                            >
                              Invoice Number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Invoice Number"
                              value={invoiceNumber}
                              onChange={(e) => setInvoiceNumber(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-company"
                            >
                              Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="basic-default-date"
                              placeholder="Enter date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
    
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-email"
                            >
                              Customer Name
                            </label>
                            <div className="input-group input-group-merge">
                              <input
                                type="text"
                                id="basic-default-email"
                                className="form-control"
                                placeholder="Enter Customer Name"
                                aria-label="john.doe"
                                aria-describedby="basic-default-email2"
                                value={customerName}
                                onChange={(e) => setCustomerName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Mobile Number"
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                              maxLength={10}
                            />
                          </div>
    
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Address
                            </label>
                            <textarea
                              id="basic-default-message"
                              className="form-control"
                              placeholder="Enter Address"
                              defaultValue={""}
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              rows={1}
                            />
                          </div>
        {/* ....................................................................................................................................  */}
                          <hr className="mb-3 mt-3" />
          {/* ................................................................................................[Billing Details]........................................  */}
                       
                 <h5 className="mb-4">Billing Details</h5>
                          
                 <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Pending Amount
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Pending Amount"
                              value={pendingAmount}
                              onChange={(e) => setPendingAmount(e.target.value)}
                             
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Paid Amount
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Paid Amount"
                              value={paidAmount}
                              onChange={(e) => setPaidAmount(e.target.value)}
                             
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Available Balance
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Available Balance"
                              value={availableBalance}
                              onChange={(e) => setAvailableBalance(e.target.value)}
                             
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payment Mode
                        </label>
                        <select
                          className="form-select"
                          id="basic-default-company"
                          defaultValue=""
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Payment Mode
                          </option>
                          <option value="Cash">Cash</option>
                          <option value="Credit Card">Credit Card</option>
                          <option value="Debit Card">Debit Card</option>
                        
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Tax No
                            </label>
                            <input
                              type="number"
                              id="basic-default-phone"
                              className="form-control phone-mask"
                              placeholder="Enter Tax No"
                              value={tax}
                              onChange={(e) => setTax(e.target.value)}
                             
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-phone"
                            >
                              Description
                            </label>
                            <textarea
                              id="basic-default-message"
                              className="form-control"
                              placeholder="Enter Description"
                              defaultValue={""}
                              value={desc}
                              onChange={(e) => setDesc(e.target.value)}
                              rows={1}
                            />
                          </div>
                          <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          
                        >
                          Submit
                        </button>
                        <button type="button" className="btn btn-danger">
                          Cancel
                        </button>
                      </div>
     {/* .................................................................................................................................                        */}
                           
   
                          
                          
                        </div>
                      </div>
                    </div>
                   
                  </div>
                
                  <Footer />
                
                </div>
           
              </div>
            
            </div>
          </div>
        </>
  )
}
