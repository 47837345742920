import React, { useEffect, useState } from 'react';
import Footer from '../Header/Footer';
import Header from '../Header/Header';
import Config from '../Header/Config';
import { navigate, useLocation, useNavigate } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';



export default function PurchaseUpdate() {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [date, setDate] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [address, setAddress] = useState('');
  const [modelName, setModelName] = useState('');
  const [color,setColor] = useState('');
  const [imei, setImei] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  let [subTotal, setSubTotal] = useState(0);
  let [balance, setBalance] = useState('');
  const [payableAmount, setPayableAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [tableData, setTableData] = useState([]);
 

  const [selectedRow, setSelectedRow] = useState(null); // New state to store the selected row
  //...........................................................................................................................................
  const apiUrl = Config.apiUrl;
  const navigate = useNavigate();

  const location = useLocation();
  
const searchParams = new URLSearchParams(location.search);
const invoice_Number = searchParams.get("invoice_no");

const imeiParam = searchParams.get("id");
const idParam = parseInt(imeiParam);



//.........................................................................

const handleBrandUpdate = async (row) => {
  const { id } = row;
  setSelectedRow(row);
  const queryParams = new URLSearchParams({
    invoice_no: invoice_Number,
    id: id, // Make sure to pass the correct 'id' value here
  });
  navigate(`/purchase_update?${queryParams}`);
};
//........................................................[supplier ,payment details].................................................................................
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/get_purchaseInvoice/${invoice_Number}`);

      const data = await response.json();
      const combineData=data.data
      setTableData(combineData)

      setInvoiceNumber(data.data[0].invoice_no);
      setDate(data.data[0].date);
      setSupplierName(data.data[0].supplier_name);
      setMobileNumber(data.data[0].mobile_no);
      setAddress(data.data[0].address);
     
      setPayableAmount(data.data[0].payable_amount);
     
      setPaymentMode(data.data[0].payment_mode);
      setSubTotal(data.data[0].sub_total)
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, [invoice_Number]);
//........................................[ fetch data for brand table].....................................................................................................



//..................................................[set brand details in Fields ]............................................................................................

const [totalPurchasePrice, setTotalPurchasePrice] = useState(0);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (idParam !== null) {
          const response = await fetch(`${apiUrl}/get_purchaseInvoice/${invoice_Number}`);
          const data = await response.json();
      console.log(data)
          const brandDetails = data.data.filter((item) => item.id === idParam);
       
          setModelName(brandDetails[0].model_name);
          setColor(brandDetails[0].color);  
          setImei(brandDetails[0].imei);
          setPrice(brandDetails[0].purchase_price);
          setQuantity(brandDetails[0].sale_price);
          
          const itemsWithInvoiceNumber = data.data.filter((item) => item.invoice_no === invoice_Number);

          const sumOfPurchasePrices = itemsWithInvoiceNumber.reduce(
            (total, item) => Number(total) + Number(item.purchase_price), 0
          );

          setTotalPurchasePrice(sumOfPurchasePrices);
          setSubTotal(sumOfPurchasePrices);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [idParam]);

//...................................................[ Update brand Items].....................................................................................
const [showBrandListTable, setShowBrandListTable] = useState(true); // Default: show the table    
        
const updateBrandDetails = async () => {
  const item = {
    model_name: modelName,
    color: color,
    imei: imei,
    purchase_price: price,
    sale_price: quantity,
  };

  try {
    const result = await fetch(`${apiUrl}/update_purchaseItem/${idParam}/${invoice_Number}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    if (result.ok) {
      alert("purchase_items updated successfully");

      // After updating the item, fetch the data again to get the latest changes
      const response = await fetch(`${apiUrl}/get_purchaseInvoice/${invoice_Number}`);
      const data = await response.json();

      // Filter the data based on the invoice number
      const itemsWithInvoiceNumber = data.data.filter((item) => item.invoice_no === invoice_Number);

      // Calculate the sum of purchase prices for items with the same invoice number
      const sumOfPurchasePrices = itemsWithInvoiceNumber.reduce(
        (total, item) => Number(total) + Number(item.purchase_price),
        0
      );

      setTableData(itemsWithInvoiceNumber);
      setTotalPurchasePrice(sumOfPurchasePrices);
      setSubTotal(sumOfPurchasePrices);

      // Clear the input fields
      setModelName("");
      setImei("");
      setColor("");
      setQuantity("");
      setPrice("");
    } else {
      alert("Failed to update purchase_items");
    }
  } catch (error) {
    console.log(error);
    alert("Failed to update purchase_items");
  }
};


//.........................................[ update purchaseDetails ].......................................

const[updatedDetails,setUpdatedDetails]=useState([])
const updatepurchaseDetails = async () => {
  const item = {
    date:date,
    supplier_name:supplierName,
    mobile_no:mobileNumber,
    address:address,
    sub_total:subTotal,
    balance_amount:balance, 
    payable_amount:payableAmount,
    payment_mode:paymentMode
    
  };

  try {
    const result = await fetch(`${apiUrl}/update_purchaseDetails/${invoice_Number}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    if (result.ok) {
      alert("purchase_items updated successfully");
      setShowBrandListTable(false);
      setUpdatedDetails(updatedDetails);

      setInvoiceNumber("");
      setDate("");
      setSupplierName("");
      setMobileNumber("");
      setAddress("");
      setPayableAmount('');
      setBalance("");
      setPaymentMode("");
      setSubTotal("");
      //....................
      setModelName("");
      setImei("");
      setPrice("");
      setQuantity("");
      setColor("");
     
     


    } else {
      alert("Failed to update purchase_items");
    }
  } catch (error) {
    console.log(error);
    alert("Failed to update purchase_items");
  }
};
//...........................................................................................


  // Delete Record from database
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
  
    if (confirmDelete) {
      fetch(`${apiUrl}/delete_purchaseItems/${id}`, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then(() => {
          const deleteItem = tableData.find((item) => item.id === id);
        const newSubTotal = Number(subTotal) - Number(deleteItem.purchase_price);
        setSubTotal(newSubTotal);
          setTableData((preData) => preData.filter((brand) => brand.id !== id));
        })
        .catch((error) => {
          console.error("Error deleting data:", error);
        });
    }
  };
  
  //.............................

 
  const [modelList, setModelList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_models`);
        const data = await response.json();
        setModelList(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
       
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Purchase Details</h4>
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Supplier Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-fullname">
                          Invoice Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="basic-default-fullname"
                          placeholder="Enter Invoice Number"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-company">
                          Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="basic-default-date"
                          placeholder="Enter date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-email">
                          Supplier Name
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            id="basic-default-email"
                            className="form-control"
                            placeholder="Enter Supplier Name"
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            value={supplierName}
                            onChange={(e) => setSupplierName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-phone">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          maxLength={10}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-phone">
                          Address
                        </label>
                        <textarea
                          id="basic-default-message"
                          className="form-control"
                          placeholder="Enter Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          rows={1}
                        />
                      </div>
                      <hr className="mb-3 mt-3" />
                      
                      <h5 className="mb-4">Brand Details</h5>
                      <div className="col-md-6 mb-3">
                        <div className="row">
                        <div className="col-md-6 mb-3">
  <label className="form-label" htmlFor="basic-default-company">
    Model Name
  </label>
  <input
    type="text"
    className="form-control"
    list="browsers"
    name="browser"
    id="browser"
    placeholder="Enter Model Name"
    value={modelName}
    onChange={(e) => setModelName(e.target.value)}
  />
  <datalist id="browsers">
    {modelList.map((model, index) => (
      <option key={index} value={model.model_name} />
    ))}
  </datalist>
</div>
<div className="col-md-6 mb-3">
  <label className="form-label" htmlFor="basic-default-company">
    Color
  </label>
  <input
    type="text"
    className="form-control"
    id="basic-default-company"
    placeholder="Enter Color"
    value={color}
    onChange={(e) => setColor(e.target.value)}
  />
</div>

                        
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-phone">
                          IMEI
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter IMEI"
                          value={imei}
                          onChange={(e) => setImei(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-phone">
                          Purchase Price
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-phone">
                          Sale Price
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Sale Price"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                      <div className="col-12">
                        <div className="col-12 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginLeft: '5px', marginRight: '5px' }}
                            onClick={(e) => updateBrandDetails(e, idParam, invoice_Number)}
                          >
                            Update
                          </button>
                        </div>
                        {showBrandListTable && (
                          <div className="card mt-3">
                            <h5 className="card-header">Brand List</h5>
                            <div className="table-responsive text-nowrap">
                              <table className="table">
                                <thead>
                                  <tr className="text-nowrap">
                                    <th>SN</th>
                                    <th>Model Name</th>
                                    <th>IMEI</th>
                                    <th>Color</th>
                                    <th>Purchase Price</th>
                                    <th>Sale Price</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData.map((row, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{row.model_name}</td>
                                      <td>{row.color}</td>
                                      <td>{row.imei}</td>
                                      <td>{row.purchase_price}</td>
                                      <td>{row.sale_price}</td>
                                      <td>
                                       
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                          onClick={() => handleBrandUpdate(row)}
                                        >
                                                  
                                          <FaEdit/>
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          style={{marginLeft:"5px"}}
                                          onClick={() => handleDelete(row.id)}
                                        >
                                          <FaTrashAlt/>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                      
                      <hr className="mb-3 mt-3" />
                      <h5 className="mb-4">Payment Details</h5>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-company">
                          Sub Total
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Sub Total"
                          value={subTotal}
                          onChange={(e) => setSubTotal(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-phone">
                          Payable Amount
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Payable Amount"
                          value={payableAmount}
                          onChange={(e) => setPayableAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-company">
                          Balance
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Balance"
                          value={
                              (balance =
                                Number(subTotal) -
                                Number(payableAmount))
                            }
                          onChange={(e) => setBalance(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label" htmlFor="basic-default-phone">
                          Payment Mode
                        </label>
                        <select
                          className="form-select"
                          id="basic-default-company"
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Payment Mode
                          </option>
                          <option value="Credit Card">Credit Card</option>
                          <option value="Debit Card">Debit Card</option>
                        </select>
                      </div>
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: '5px', marginRight: '5px' }}
                          onClick={()=>updatepurchaseDetails()}
                        >
                          Update
                        </button>
                        <button type="button" className="btn btn-danger">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
