import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { useLocation } from "react-router-dom";
import { FaTrash, FaTrashAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Supplier_pagination from "../Header/Supplier_pagination";

export default function Purchase() {
  const [imeiNotFound, setImeiNotFound] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState(today);
  const [supplierName, setSupplierName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");

  let [modelName, setModelName] = useState("");
  let [modelName1, setModelName1] = useState("");
  const [imei, setImei] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [color, setColor] = useState("");

  let [subTotal, setSubTotal] = useState(0);
  let [balance, setBalance] = useState(0);

  let [payableAmount, setPayableAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [file, setFile] = useState(null);

 



  const [tableData, setTableData] = useState([]);

  const [shouldFetchData, setShouldFetchData] = useState(false);

  const apiUrl = Config.apiUrl;

  //....................................................................... [ Add Brand Details ].............................................

  const addBrandDetails = async (e) => {
    e.preventDefault();
    const item = {
      invoice_no: invoiceNumber,
      model_name: modelName1,
      imei: imei,
      purchase_price: price,
      sale_price: quantity,
      color: color,
    };

    try {
      const result = await fetch(`${apiUrl}/purchase_items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.ok) {
        toast.success("purchase_items added successfully");
        setTableData((prevTableData) => [...prevTableData, item]);
        setModelName1("");
        setModelName("");
        setImei("");
        setQuantity("");
        setPrice(0);
        setColor("");
        setSubTotal((prevSubTotal) => prevSubTotal + parseFloat(price));
      } else {
        alert("Failed to add purchase_items");
      }
    } catch (error) {
      console.log(error);
      alert("Failed to add purchase_items");
    }
  };
  //  .................................................................... [ Add All Purchase Details ]......................................

const addAllDetails = async () => {
  const data = new FormData();
  data.append("invoice_no", invoiceNumber);
  data.append("date", date);
  data.append("supplier_name", supplierName);
  data.append("mobile_no", mobileNumber);
  data.append("address", address);
  data.append("sub_total", subTotal);
  data.append("balance_amount", balance);
  data.append("payable_amount", payableAmount);
  data.append("payment_mode", paymentMode);
  data.append("file", file);

  try {
    console.log("Sending request with data:", data);

    const result = await fetch(`${apiUrl}/purchase_details`, {
      method: "POST",
      body: data,
    });

    console.log("Response from server:", result);

    if (result.ok) {
      toast.success("purchase_Details added successfully");
      console.log(file);
      setTableData([]);

      // Reset your state
      setInvoiceNumber("");
      setDate("");
      setSupplierName("");
      setMobileNumber("");
      setAddress("");
      setSubTotal(0);
      setBalance(0);
      setPayableAmount("");
      setPaymentMode("");
      setFile(null);
    } else {
      toast.error("Failed to add purchase_Details");
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error("Failed to add purchase_Details");
  }
};

  
  
  
  
  
  // ....................................................................[ fetch Purchase Item ]..................................

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_purchaseItems`);
        const data = await response.json();
        setTableData(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    if (shouldFetchData) {
      fetchData();
    }
  }, [shouldFetchData]);

  //........................................................................[ fetch All Models ].................................

  const [modelList, setModelList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_models`);
        const data = await response.json();
        setModelList(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const [suppilerList, setSuppilerList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_supplier`);
        const data = await response.json();
        setSuppilerList(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  //................................................................ [  fetch data using Mobile number  ] ..........................................

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/get_all_purchaseDetails`);
      const data = await response.json();

      const filterData = data.data.filter(
        (item) => item.mobile_no === mobileNumber
      );
      console.log(filterData);

      console.log(filterData.length);
      const len = filterData.length;

      //.................................................. [ set Balance when mobile number is correct otherwise display 0 and print error msg] ...........................

      if (len > 0) {
        console.log(filterData[len - 1].balance_amount);
        console.log("Sum of Balance: " + filterData[len - 1].balance_amount);
        const balanceAmount =
          mobileNumber.length === 10 ? filterData[len - 1].balance_amount : 0;
        setBalance(balanceAmount);
        setImeiNotFound(balanceAmount === 0);
      } else if (mobileNumber.length > 0) {
        console.log("No matching purchase data found.");
        setBalance(0);
        setImeiNotFound(true);
      } else {
        console.log("Please enter a valid mobile number.");
      }

      //........................................................................................................................

      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [mobileNumber]);

  //................................................................... [ filer Data Using Invoice Number ] ...........................................................

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invoice_Number = searchParams.get("invoice_no");

  const [buyBackData, setBuyBackData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (invoice_Number) {
          const response = await fetch(`${apiUrl}/get_all_returnBrand`);
          const data = await response.json();

          const filterData = data.data.filter(
            (item) => item.invoice_no === invoice_Number
          );
          console.log(filterData);

          setBuyBackData(filterData);

          // Assuming these functions exist to set state
          setImei(filterData[0].imei);
          setModelName1(filterData[0].model_name);
          setPrice(filterData[0].price);
          setColor(filterData[0].color);
          setInvoiceNumber(filterData[0].invoice_no);

          console.log(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [invoice_Number]);

  //...................................................................[ fetch All SAles Details using invoice number]....................................

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_salesDetails`);
        const data = await response.json();

        const filterData = data.data.filter(
          (item) => item.invoice_no === invoice_Number
        );
        console.log(filterData);

        setDate(filterData[0].date);

        setSupplierName(filterData[0].customer_name);
        setAddress(filterData[0].address);
        setMobileNumber(filterData[0].mobile_no);

        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [invoice_Number]);

  //.....................................................................


  
  return (
    <>
      <ToastContainer autoClose="1500" />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 pt-5">
                <div className="row">
                  <div className="col-md-7">
                    <h4 className="fw-bold  mb-4">Purchase Details</h4>
                  </div>
                  <div className="col-md-5">
                    <Supplier_pagination />
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Supplier Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Invoice Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="basic-default-fullname"
                          placeholder="Enter Invoice Number"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="basic-default-date"
                          placeholder="Enter date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-email"
                        >
                          Supplier Name
                        </label>
                        <div className="input-group input-group-merge">
                          <select
                            className="form-control"
                            id="basic-default-email"
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            value={supplierName}
                            onChange={(e) => setSupplierName(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Supplier
                            </option>
                            {suppilerList.map((supplier, index) => (
                              <option
                                key={index}
                                value={supplier.supplier_name}
                              >
                                {supplier.supplier_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          maxLength={10}
                        />
                        {imeiNotFound && (
                          <span style={{ color: "red" }}>
                            Mobile Number not found
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Address
                        </label>
                        <textarea
                          id="basic-default-message"
                          className="form-control"
                          placeholder="Enter Address"
                          defaultValue={""}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          rows={1}
                        />
                      </div>
                      <hr className="mb-3 mt-3" />
                      <h5 className="mb-4">Brand Details</h5>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="basic-default-company"
                                >
                                  Model Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  list="browsers"
                                  name="browser"
                                  id="browser"
                                  placeholder="Enter Model Name"
                                  value={modelName1}
                                  onChange={(e) =>
                                    setModelName1(e.target.value)
                                  }
                                />
                                <datalist id="browsers">
                                  {modelList.map((model, index) => (
                                    <option
                                      key={index}
                                      value={model.model_name}
                                    />
                                  ))}
                                </datalist>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="basic-default-company"
                                >
                                  Color
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-company"
                                  placeholder="Enter Color"
                                  value={color}
                                  onChange={(e) => setColor(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          IMEI
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter IMEI"
                          value={imei}
                          onChange={(e) => setImei(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Purchase Price
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Sale Price
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Sale Price"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                      <div className="col-12">
                        <div className="col-12 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginLeft: "5px", marginRight: "5px" }}
                            onClick={addBrandDetails}
                          >
                            Add
                          </button>
                        </div>
                        {tableData.length > 0 ? (
                          <div className="card mt-3">
                            <h5 className="card-header">Brand List</h5>
                            <div className="table-responsive text-nowrap">
                              <table className="table">
                                <thead>
                                  <tr className="text-nowrap">
                                    <th>SN</th>
                                    <th>Model Name</th>
                                    <th>Color</th>
                                    <th>IMEI</th>
                                    <th>Purchase Price</th>
                                    <th>Sale Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData.map((row, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{row.model_name}</td>
                                      <td>{row.color}</td>
                                      <td>{row.imei}</td>
                                      <td>{row.purchase_price}</td>
                                      <td>{row.sale_price}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div className=""></div>
                        )}
                      </div>
                      <hr className="mb-3 mt-3" />
                      <h5 className="mb-4">Payment Details</h5>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Sub Total
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Sub Total"
                          value={subTotal}
                          onChange={(e) => setSubTotal(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payable Amount
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Payable Amount"
                          value={payableAmount}
                          onChange={(e) => setPayableAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Balance
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Balance"
                          value={
                            (balance =
                              Number(balance) +
                              Number(subTotal) -
                              Number(payableAmount))
                          }
                          onChange={(e) => setBalance(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payment Mode
                        </label>
                        <select
                          className="form-select"
                          id="basic-default-company"
                          defaultValue=""
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Payment Mode
                          </option>
                          <option value="Credit Card">Credit Card</option>
                          <option value="Debit Card">Debit Card</option>
                          <option value="Net Banking">Net Banking</option>
                          <option value="UPI">UPI</option>
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
  <label className="form-label" htmlFor="basic-default-phone">
    Upload File
  </label>
  <input
    type="file"
    id="basic-default-phone"
    className="form-control"
    onChange={(e) => setFile(e.target.files[0])}
  />
</div>

                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          onClick={addAllDetails}
                        >
                          Submit
                        </button>
                        <button type="button" className="btn btn-danger">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
