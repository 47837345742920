import React, { useEffect, useState } from 'react'
import { FaPrint } from 'react-icons/fa'
import { useNavigate ,useLocation} from 'react-router-dom';
import Config from '../Header/Config';
import converter from 'number-to-words';
export default function Purchase_invoice() {
  
const[name,setName]=useState('');
const [date,setDate] = useState('');
const [mobileNumber,setMobileNumber] = useState('');
const [grandTotal,setGrandTotal] = useState('');
const [discount,setDiscount] = useState('');
const[subTotal,setSubTotal] = useState('');
const [invoice,setInvoice] = useState('');
const [loading, setLoading] = useState(true);
  const [tabledata, setTableData] = useState('')
  const apiUrl = Config.apiUrl

//................................{ set data in invoice }...............................
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const invoice_Number=searchParams.get('invoice_no');


//......................{ fetch brand details }.......................

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/get_purchaseInvoice/${invoice_Number}`);
      const data = await response.json();

      const filterData = data.data.filter((item) => item.invoice_no === invoice_Number);
      console.log(data);

      setTableData(filterData);
       
      setName(filterData[0].supplier_name)
    setDate(filterData[0].date)
    setMobileNumber(filterData[0].mobile_no)
    setGrandTotal(filterData[0].grand_total)
    setDiscount(filterData[0].discount)
    setSubTotal(filterData[0].sub_total)
    setInvoice(filterData[0].invoice_no)
     setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, [invoice_Number]);

//......................{ after print Hide buttons }.....................

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/purchase_table");
  };

  const [showDiv, setShowDiv] = useState(true);

  const handlePrint = () => {
    setShowDiv(false);
    setTimeout(() => {
      window.print();
      setShowDiv(true);
    }, 0);
  };

  useEffect(() => {
    const handleBeforePrint = () => {
      setShowDiv(false);
    };

    const handleAfterPrint = () => {
      setShowDiv(true);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

// Assuming grandTotal is a string representation of a number
const parsedGrandTotal = parseFloat(subTotal); // or parseInt(grandTotal, 10) if it's an integer
  
  
  return (
    <>
     {loading ? ( // Render loading message when loading is true
          <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"100px"}}>Loading...</div>

          ) : (
    <section style={{backgroundColor:"white"}}>
    <div style={{ padding: "20px" }}>
      <div style={{ margin: 'auto', padding: '30px 24%', position: 'relative' }}>
        {showDiv && (
          <>
            <div style={{ margin: "auto", padding: "30px 0px", position: "relative", maxWidth: "210mm" }}>
              <div style={{ float: "left" }}>
                <button className='btn btn-outline-primary' onClick={handleBack}>Back</button>
              </div>
              <div style={{ float: "right" }}>
                <button className='btn btn-outline-danger' onClick={handlePrint}><FaPrint /></button>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="invoice-boxx">
        <div className="invoice-header">
        <table>
            <tbody>
              <tr>
                <td style={{ border: "1px solid #dddddd", width: "50%" }}>
                 
                  <b>Name : {name}</b>
                  <br />

                  <b> Mobile No: {mobileNumber}</b>

                  <br />
                </td>
                <td style={{ border: "1px solid #dddddd", width: "50%" }}>
                  
                  <b>Date : {date}</b>
                  <br />
                  <b>Invoice No: {invoice}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr style={{ border: "1px solid #dddddd", width: "50%" }}>
                <p
                  className=" text-center"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "35px",
                    padding: "0px 10px",
                    lineHeight: "1.1"
                  }}
                > <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    
                    <div style={{ width: "100%" }}> <center><h1 className='mt-3' ><b>Mobile City</b> </h1> </center>
                      <center><h5 style={{ fontSize: "12px"}}><b>Secound hand mobiles & Accessories</b></h5></center></div>
                  </div>
                </p>
                <td style={{ width: "50%", border: "1px solid #dddddd" }}>
                  <p style={{ lineHeight: " 1.2" }}>
                    <b>Baburao</b>
                    <br />
                    <span className="fw-200">
                      Docotors Colony, near Tejam Hospital, Gadhinglaj, Dist- Kolhapur Pin- 416 502
                    </span>{" "}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <div style={{ height: "50%" }}>
          <table className="tables">
            <thead>
              <tr style={{ border: "1px solid #dddddd" }}>
                <th style={{ border: "1px solid #dddddd", width: "5%" }}>
                  Sr No
                </th>
                <th style={{ border: "1px solid #dddddd", width: "30%" }}>
                  Product
                </th>
                <th style={{ border: "1px solid #dddddd", width: "10%" }}>
                  IMEI
                </th>
                <th style={{ border: "1px solid #dddddd", width: "10%" }}>
                  Quantity
                </th>
                <th style={{ border: "1px solid #dddddd", width: "10%" }}>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(tabledata) &&
                tabledata.map((row, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid #dddddd" }}>{index + 1}</td>
                    <td style={{ border: "1px solid #dddddd" }}>
                      {row.model_name}
                    </td>
                    <td style={{ border: "1px solid #dddddd" }}>
                      {row.imei}
                    </td>
                    <td style={{ border: "1px solid #dddddd" }}>{1}</td>
                    <td style={{ border: "1px solid #dddddd" }}>&#8377;{row.purchase_price}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div style={{ border: "1px solid #dddddd", height: "10%" }}>
          <div
            style={{
              width: "55%",
              float: "left",
              borderRight: "1px solid #dddddd",
              height: "100%",
            }}
          >
            <center>
              <b>Authorized Signature</b>
            </center>
          </div>
          <div style={{ width: "45%", float: "right", height: "100%" }}>
            <div style={{ width: "65%", float: "left", height: "100%" }}>
              <p
                style={{
                  margin: "0",
                  borderBottom: "1px solid #dddddd",
                  borderRight: "1px solid #dddddd",
                  padding: "0px 5px",
                }}
              >
                Sub Total
              </p>
              <p
                style={{
                  margin: "0",
                  borderBottom: "1px solid #dddddd",
                  borderRight: "1px solid #dddddd",
                  padding: "0px 5px",
                }}
              >
                Discount
              </p>
              <b>
                {" "}
                <p
                  style={{
                    margin: "0",
                    borderRight: "1px solid #dddddd",
                    padding: "0px 5px",
                  }}
                >
                  Grand Total
                </p>
              </b>
            </div>
            <div style={{ width: "35%", float: "right", height: "100%" }}>
              <p
                style={{
                  margin: "0",
                  borderBottom: "1px solid #dddddd",
                  padding: "0px 5px",
                }}
              >
               &#8377;{subTotal}/-
              </p>
              <p
                style={{
                  margin: "0",
                  borderBottom: "1px solid #dddddd",
                  padding: "0px 5px",
                }}
              >
                {discount}/-
              </p>
              <b>
                {" "}
                <p style={{ margin: "0", padding: "0px 5px" }}>
                {grandTotal}/-
                </p>
              </b>
            </div>
          </div>
        </div>
        <b>
          {" "}
          <p style={{ padding: "10px 5px" }}>
          Total Amount In Word :  {isNaN(parsedGrandTotal) ? "Invalid amount" : converter.toWords(parsedGrandTotal)}  rupees only{" "}
          </p>
        </b>
      
        {/* <table className="tables">
        <tbody>
          <tr style={{ border: "1px solid #dddddd" }}>
            <td style={{ width: "55%", borderRight: "1px solid #dddddd" }}>
              <h4 className="text-right">Total Amount</h4>
            </td>
            <tr style={{ width: "45%" }}>
             <td> Total : {total}/-</td>
             <td>Discount : {discount}/-</td>
             <td>Grand Total : {grandTotal}/- </td>
            </tr>
          </tr>
        </tbody>
      </table> */}
      </div>
    </div>
    </section>
     )}
    </>
  )
}
