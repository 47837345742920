import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import { FaEye, FaEyeSlash, FaShoppingCart, FaTrashAlt } from "react-icons/fa";
import Config from "../Header/Config";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";

export default function User_udate() {
          const apiUrl = Config.apiUrl;
          const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

 
  const navigate=useNavigate();

  const [showPassword, setShowPassword] = useState("");
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  //........................................

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user_id) {
          const response = await fetch(`${apiUrl}/searchUser/${user_id}`);
          const data = await response.json();
          console.log(data);
          setName(data.data.full_name)
          setEmail(data.data.email)
          setGender(data.data.gender)
          setPassword(data.data.password)
          setMobileNumber(data.data.contact)
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user_id]);

  //.............................................................

  const[updatedDetails,setUpdatedDetails]=useState([])
const updateUserDetails = async () => {
  const item = {
          full_name: name,
          gender: gender,
          contact: mobileNumber,
          email: email,
          password: password,
    
  };

  try {
    const result = await fetch(`${apiUrl}/createUser/${user_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    if (result.ok) {
      alert("User updated successfully");
      
      setUpdatedDetails(updatedDetails);

     setEmail('');
     setGender('');
     setName('');
     setMobileNumber('');
     setPassword( '');


     
      navigate("/user")


    } else {
      alert("Failed to update User");
    }
  } catch (error) {
    console.log(error);
    alert("Failed to update User");
  }
};

 
  return (
          <div className="layout-wrapper layout-content-navbar">
      
      
          <div className="layout-container">
            <Header />
    
            <div className="layout-page">
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <h4 className="fw-bold py-3 mb-4">User Profile Update</h4>
    
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">User Profile</h5>
                    </div>
                    <div>
                      <div className="card-body">
                        <div>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-email"
                              >
                                Name
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  type="text"
                                  id="basic-default-email"
                                  className="form-control"
                                  placeholder="Enter Name"
                                  aria-label="john.doe"
                                  aria-describedby="basic-default-email2"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-email"
                              >
                                Gender
                              </label>
                              <div className="flex">
                                <div
                                  className="form-check"
                                  style={{
                                    display: "inline-flex",
                                    marginRight: "20px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="maleOption"
                                    name="genderOption"
                                    value="Male" // Set different values for the radio buttons
                                    checked={gender === "Male"} // Check if gender is "Male"
                                    onChange={(e) => setGender(e.target.value)} // Update the gender state on change
                                  />
                                  <label
                                    style={{ marginLeft: "7px" }}
                                    className="form-check-label"
                                    htmlFor="maleOption"
                                  >
                                    Male
                                  </label>
                                </div>
                                <div
                                  className="form-check"
                                  style={{
                                    display: "inline-flex",
                                    marginRight: "7px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="femaleOption"
                                    name="genderOption"
                                    value="Female" // Set different values for the radio buttons
                                    checked={gender === "Female"} // Check if gender is "Female"
                                    onChange={(e) => setGender(e.target.value)} // Update the gender state on change
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="femaleOption"
                                    style={{ marginLeft: "7px" }}
                                  >
                                    Female
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-phone"
                              >
                                Mobile Number
                              </label>
                              <input
                                type="text"
                                id="basic-default-phone"
                                className="form-control phone-mask"
                                placeholder="Enter Mobile Number"
                                maxLength={10}
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                              />
                            </div>
    
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-phone"
                              >
                                Email Id
                              </label>
                              <input
                                type="email"
                                id="basic-default-message"
                                className="form-control"
                                placeholder="Enter Email ID"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
    
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-message"
                              >
                                Password
                              </label>
                              <div className="input-group">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  id="basic-default-message"
                                  className="form-control"
                                  placeholder="Enter Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <span
                                  className="input-group-text"
                                  onClick={handleTogglePassword}
                                >
                                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                              </div>
                            </div>
    
                            <div className="col-12">
                              <div className="col-12 d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{ marginLeft: "5px", marginRight: "5px" }}
                                  onClick={()=>updateUserDetails()}
                                >

                                  Update
                                </button>
    
                                <button type="button" className="btn btn-danger"
                              
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>  
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
    
                <Footer />
    
                <div className="content-backdrop fade" />
              </div>
            </div>
          </div>
    
          <div className="layout-overlay layout-menu-toggle" />
        </div>
  )
}
