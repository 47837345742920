import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Header/Footer';

export default function Leager_general_table() {
          const [date1, setDate1] = useState(""); 
          const [date2, setDate2] = useState("");
        
            // Set date1 to 31st March
            useEffect(() => {
              const march31 = new Date(new Date().getFullYear(), 2, 31); // Note that months are 0-indexed, so 2 represents March
              setDate1(march31.toISOString().substr(0, 10)); // Format date1 as "YYYY-MM-DD"
            }, []);
          
            // Set date2 to today's date
            useEffect(() => {
              const today = new Date();
              setDate2(today.toISOString().substr(0, 10)); // Format date2 as "YYYY-MM-DD"
            }, []);
          
            // Rest of your component code...
          
          const navigate = useNavigate();
        
          const handleUpdate = () => {
            navigate(`/general_leager?from=${date1}&to=${date2}`);
          };
  return (
          <>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              {/* Menu */}
              <Header />
              {/* / Menu */}
              {/* Layout container */}
              <div className="col-6 layout-page">
                {/* Navbar */}
                {/* / Navbar */}
                {/* Content wrapper */}
                <div className="content-wrapper">
                  {/* Content */}
                  <div className="container-xxl flex-grow-1 container-p-y col-12">
                    <h4 className="fw-bold py-3 mb-4">General leager</h4>
                    {/* Basic Layout */}
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">General leager</h5>
                      </div>
                      <div className="card-body">
                        <div className="row align-items-end mb-3">
                          <div className="col-md-2"></div>
                          <div className="form-group col-md-4 mb-0">
                            <label className="form-label" htmlFor="start-date">
                              From
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="start-date"
                              value={date1}
                              onChange={(e) => setDate1(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-md-4 mb-0">
                            <label className="form-label" htmlFor="end-date">
                              To
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="end-date"
                              value={date2}
                              onChange={(e) => setDate2(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-md-2 mb-0 d-flex align-items-center justify-content-md-start justify-content-center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={() => handleUpdate()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* / Content */}
                  {/* Footer */}
                  <Footer />
                  {/* / Footer */}
                  <div className="content-backdrop fade" />
                </div>
                {/* Content wrapper */}
              </div>
              {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
          </div>
          {/* / Layout wrapper */}
        </>
  )
}
