import React, { useCallback, useEffect, useState } from "react";

import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { clear } from "@testing-library/user-event/dist/clear";
import { ToastContainer, toast } from 'react-toastify';


export default function Model() {
  const [model, setModel] = useState("");
  const [color, setColor] = useState("");
  const [openingStock, setOpeningStock] = useState('');
  const [description, setDescription] = useState("");

  const apiUrl = Config.apiUrl;

  //.............{ Add Model }.........................
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/get_all_purchaseItems`);
      const data = await response.json();
      setOpeningStock(data.data.length);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addModel = async (e) => {
    e.preventDefault();
    let item = {
      model_name: model.toUpperCase(),
      color_name: color,
      opening_stock: openingStock,
      description: description,
    };

    try {
      const response = await fetch(`${apiUrl}/get_all_models`);
      const data = await response.json();

      const isModelExists = data.data.some(
        (row) => row.model_name === model.toUpperCase()
      );

      if (isModelExists) {
        toast.danger('Model already exists');
      } else {
        const result = await fetch(`${apiUrl}/save_model`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(item),
        });

        if (result.ok) {
          console.log(openingStock);
          toast.success('Model added successfully',{autoClose:1500});
          setModel('');
          setColor('');
          setOpeningStock(0);
          setDescription('');
          fetchData(); // Fetch data again to update the opening stock after adding the new model
        } else {
          toast.success('Failed to add Model',{autoClose:1500});
        }
      }
    } catch (error) {
      console.log(error);
      toast.warning('Model already exists',{autoClose:1500});
    }
  };
  

  //-------------- {  Cancel Model  }----------------------

  const cancelModel = () => {
    setModel("");
    setColor("");
   
    setDescription("");
  };


  
  return (
    <>
    <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Model Profile</h4>

                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Model Profile</h5>
                  </div>
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-email"
                          >
                            Model
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              type="text"
                              id="basic-default-email"
                              className="form-control"
                              placeholder="Enter Model Name"
                              aria-label="john.doe"
                              aria-describedby="basic-default-email2"
                              value={model.toUpperCase()}
                              onChange={(e) => setModel(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-phone"
                          >
                            Color
                          </label>
                          <input
                            type="text"
                            id="basic-default-phone"
                            className="form-control phone-mask"
                            placeholder="Enter Color name"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-phone"
                          >
                            Opening Stock
                          </label>
                          <input
                            type="number"
                            id="basic-default-phone"
                            className="form-control phone-mask"
                            placeholder="Enter Opening Stock"
                            value={openingStock}
                            onChange={(e) => setOpeningStock(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-phone"
                          >
                            Description
                          </label>
                          <textarea
                            id="basic-default-message"
                            className="form-control"
                            placeholder="Enter description"
                            defaultValue={""}
                            value={description}
                            rows={1}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        <div className="col-12">
                          <div className="col-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                              onClick={addModel}
                            >
                              Submit   
                            </button>

                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={cancelModel}
                            >
                              Cancel 
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />

              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}
