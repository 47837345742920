import React, { useEffect, useState } from 'react'
import Config from '../Header/Config';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPrint } from 'react-icons/fa';

export default function Leager_general() {
          const [loading, setLoading] = useState(true);
          const [tableData,setTableData]=useState([])
          const location = useLocation();
          const searchParams = new URLSearchParams(location.search);
        
          const date1=searchParams.get('from');
          const date2=searchParams.get('to');
          
        
          const apiUrl=Config.apiUrl
        
          const  GetDetails = async (e)=>{
        
            
            let item={
           
              date1:date1,
              date2:date2
            };
            try {
          
              let result=await fetch(`${apiUrl}/generalLedger`,{
                method: 'POST',
                headers:{
                  "Content-Type": "application/json",
                  Accept:"application/json",
                },
                body: JSON.stringify(item),
               
              
              });
          
              if(result.ok){
               
                const data = await result.json(); 
                console.log('Response data:', data);
               setTableData(data.data)
               setLoading(false)
              }else{
                alert("failed ");
              }
              
            } catch (error) {
              console.log(error);
              alert("Failed");
              
            }
          
           }
        
           useEffect(() => {
            GetDetails();
          }, []);
        
          //.......................................................
        const navigate=useNavigate();
          const handleBack = () => {
            navigate("/general_leager_table");
          };
        
          const [showDiv, setShowDiv] = useState(true);
        
          const handlePrint = () => {
            setShowDiv(false);
            setTimeout(() => {
              window.print();
              setShowDiv(true);
            }, 0);
          };
        
          useEffect(() => {
            const handleBeforePrint = () => {
              setShowDiv(false);
            };
        
            const handleAfterPrint = () => {
              setShowDiv(true);
            };
        
            window.addEventListener('beforeprint', handleBeforePrint);
            window.addEventListener('afterprint', handleAfterPrint);
        
            return () => {
              window.removeEventListener('beforeprint', handleBeforePrint);
              window.removeEventListener('afterprint', handleAfterPrint);
            };
          }, []);

          // let runningBalance = 0;
  return (
          <> {loading ? ( // Render loading message when loading is true
          <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"100px"}}>Loading...</div>

          ) : (
          <div style={{background:"white"}}>
          <>
          
  <meta charSet="utf-8" />
  <link rel="stylesheet" href="bootstrap.css" />
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n   \n      .invoice-box {\n        max-width: 210mm;\n        margin: auto;\n        height: 260mm;\n       \n        border: 2px solid black;\n          position: relative;\n   \n      \n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        color: #555;\n      }\n\n      .invoice-header {\n        max-width: 210mm;\n    \n        font-size: 16px;\n        line-height: 24px;\n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        color: #555;\n      }\n\ntable {\n  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n}\n\n\n\n      \n\n      @media only screen and (max-width: 600px) {\n        .invoice-box table tr.top table td {\n          width: 100%;\n          display: block;\n          text-align: center;\n          margin: 5px;\n        }\n\n        .invoice-box table tr.information table td {\n          width: 100%;\n          display: block;\n          text-align: center;\n\n        }\n        .tables tfoot\n      {\n         display: table-footer-group;\n      }\n      }\n\n      /** RTL **/\n      .invoice-box.rtl {\n        direction: rtl;\n        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n      }\n\n           .tables {\n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        height: 480px;\n\n        width: 100%;\n\n      }\n     \n\n      .tables tfoot\n      {\n        bottom: 0;\n        \n      }\n\n      td, th {\n        border: 1px solid black;\n\n        text-align: left;\n        padding: 2px;\n      }\n\n\n    "
    }}
  />
  <div style={{ margin: 'auto', padding: '30px 24%', position: 'relative' }}>
       
       {showDiv && (
         <>
           <div style={{ margin: "auto", padding: "0px 0px", position: "relative", maxWidth: "210mm" }}>
             <div style={{ float: "left" }}>
               <button className='btn btn-outline-primary' onClick={handleBack}>Back</button>
             </div>
             <div style={{ float: "right" }}>
               <button className='btn btn-outline-danger' onClick={handlePrint}><FaPrint /></button>
             </div>
           </div>
         </>
       )}
     </div>
  <div className="invoice-boxxx ">
    <div className="invoice-header">
      <center>
        <h2>Mobile City</h2>
      </center>
      <center>
        <h4>General Ledger Summery</h4>
      </center>
      <table style={{ tableLayout: "fixed" }}>  
        <tbody>
         
        </tbody>
      </table>
    </div>
    <br />
   
    <table className="tables" >
      <thead >
        <tr >
          <th>Sr</th>
          <th>Date</th>
          <th>Perticular</th>
          
          <th>Credit</th>
          <th>Debit</th>
        
     
          
        </tr>
      </thead>
      <tbody>
      
       
      {Array.isArray(tableData) &&
          tableData.map((row, index) => {
        
            
            return (
              <tr key={index}>
                    <td>{index + 1}</td>
                <td>{row.date}</td>
                <td>{row.entry_name}</td>
                <td>&#8377;{row.creadit_amount}</td>
                <td>&#8377;{row.debit_amount}</td>
               
              </tr>
            );
          })}
        <tr style={{ height: "100%" }}>
          <td />
          <td />
          <td />
          <td />
          <td />
      
        </tr>
      </tbody>
      <tfoot style={{ bottom: 0 }}>
        <tr>
          <th>Date</th>
          <th>Perticular</th>
        
          <th>Credit</th>
          <th>Debit</th>
          <th>Balance  </th>
        
          
        </tr>
      </tfoot>
    </table>
  </div>
</>

      
    </div>
          )}
    </>
  )
}
