import React, { useEffect, useMemo, useState } from "react";
import Config from "../Header/Config";

import "./modal.css";
const ITEMS_PER_PAGE = 2; // Adjust the number of items to display per page

export default function SupplierPagination() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const apiUrl = Config.apiUrl;
//..............................................................[Get Supplier]................................................

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_supplier`);
        const data = await response.json();
        setTableData(data.data.reverse()); // Reverse the data array before setting it
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchTableData();
  }, [apiUrl]);
  //...........................................................................................................................

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, tableData.length);

  const closeModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
  };

  const handleModal = (supplier_name) => {
    console.log("Opening modal for ID:", supplier_name);
    setSelectedRowId(supplier_name);
    setIsModalOpen(true);
  };
//....................................................................[get Data in Modal ].............................................................

  const [brand, setBrand] = useState([]);
  const [brand1, setBrand1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [purchaseDetailsResponse, purchaseItemsResponse] =
          await Promise.all([
            fetch(`${apiUrl}/get_all_purchaseDetails`),
            fetch(`${apiUrl}/get_all_purchaseItems`),
          ]);

        const purchaseDetailsData = await purchaseDetailsResponse.json();
        const purchaseItemsData = await purchaseItemsResponse.json();

        setBrand1(purchaseDetailsData.data.reverse());
        setBrand(purchaseItemsData.data.reverse());
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Combine data
  const combinedData = useMemo(() => {
    if (brand.length === 0 || brand1.length === 0) {
      return [];
    }

    return brand.map((row) => {
      const brandItem = brand1.find(
        (item) => item.invoice_no === row.invoice_no
      );

      if (brandItem) {
        return {
          ...row,
          date: brandItem.date,
          supplier_name: brandItem.supplier_name,
        };
      }

      return row;
    });
  }, [brand, brand1]); // Change "tableData" to "brand1"

  
  // Filter data by selected supplier name
  const filteredData = combinedData.filter(
    (row) => row.supplier_name === selectedRowId
  );

  console.log("Filtered Data:", filteredData);

  return (
    <div className="card mb-2">
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <>
   {/* //.......................................................................................... */}
          <table className="table">
            <tbody style={{ display: "flex" }}>
              {Array.isArray(tableData) && tableData.length > 0 ? (
                tableData.slice(startIndex, endIndex).map((row) => (
                  <tr key={row.supplier_name}>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary popUpBtn"
                        onClick={() => handleModal(row.supplier_name)}
                      >
                        {row.supplier_name}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
   {/* //............................................................................................ */}
          {isModalOpen && (
            <div
              className="modal"
              style={{ display: isModalOpen ? "block" : "none" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <span className="close" onClick={closeModal}>
                    &times;
                  </span>
                  <h3 style={{ color: "black" }}> {selectedRowId}</h3>
                </div>
                <div className="modal-body">
                  {/* You can use selectedRowId to display corresponding data */}

                  <div className="table-responsive text-nowrap">
                    <table className="table">
                      <thead
                        className="sticky-header"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <tr className="text-nowrap">
                          <th>SN</th>
                          <th>Model Name</th>
                          <th>IMEI</th>
                          <th>Purchase Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row.model_name}</td>
                            <td>{row.imei}</td>
                            <td>{row.purchase_price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
  {/* //..................................................................................................  */}
          {/* Pagination */}
          <div
            style={{ display: "flex", justifyContent: "end", marginTop: "3px" }}
             >
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {/* Previous arrow button */}
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {"<"}
                  </button>
                </li>

                {/* Page numbers */}
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1
                ).map(
                  (pageNumber) =>
                    pageNumber >= currentPage - 2 &&
                    pageNumber <= currentPage + 2 && (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    )
                )}

                {/* Next arrow button */}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    {">"}
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
}
