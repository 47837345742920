import axios from "axios";
import React, { createContext, useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Config from "./Config";

const AuthContext = createContext();

const apiUrl = Config.apiUrl;
const AuthProvider = ({ children }) => {
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [Balance, setBalance] = useState("");

  const handleLogin = () => {
    // Check if the user is using the default admin credentials
    if (useremail === "admin" && password === "password") {
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("username", "admin");

      toast.success("Login Successful", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate("/"); // Use navigate to perform navigation after the toast is shown
      }, 2000); // Adjust the delay to match the autoClose time in the toast settings

      return; // Exit the function to prevent making an API call
    }

    axios
      .post(`${apiUrl}/userLogin`, {
        email: useremail,
        password: password,
      })
      .then((response) => {
        const { name, message } = response.data;
        if (message) {
          setIsLoggedIn(true);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("username", name);
          toast.success("Login Successful", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate("/"); // Use navigate to perform navigation after the toast is shown
          }, 2000); // Use navigate to perform navigation
        } else {
          toast.error("Login Failed!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error(
          "Login Failed! please check email and password and try again.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          } 
        );
      });
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear();

    navigate('/login'); // Use navigate to perform navigation
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        username,
        setUsername,
        password,
        setPassword,
        useremail,
        setUserEmail,
        handleLogin,
        handleLogout,
        Balance,
        setBalance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
