import React, { useEffect, useState } from 'react'
import Config from '../Header/Config';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPrint } from 'react-icons/fa';
import { useAuth } from '../Header/AuthContext';

export default function Leager_suppiler() {
  let [runningBalance, setRunningBalance] = useState(0);

  const[loading ,isLoading] =useState(true);
  const [tableData,setTableData]=useState([])
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mobileNumber=searchParams.get('mobileNumber');
  const date1=searchParams.get('date1');
  const date2=searchParams.get('date2');
  const suppilerName=searchParams.get('supplierName');
  const address=searchParams.get('address');
  const{Balance,setBalance} = useAuth();

  

  const apiUrl=Config.apiUrl

  const  GetDetails = async (e)=>{

    
    let item={
      mobile_no:mobileNumber,
      date1:date1,
      date2:date2
    };
    try {
  
      let result=await fetch(`${apiUrl}/supplierLedger`,{
        method: 'POST',
        headers:{
          "Content-Type": "application/json",
          Accept:"application/json",
        },
        body: JSON.stringify(item),
       
      
      });
  
      if(result.ok){
       
        const data = await result.json(); 
        console.log('Response data:', data);
       setTableData(data.data)
    isLoading(false)
      }else{
        alert("failed ");
      }
      
    } catch (error) {
      console.log(error);
      alert("Failed");
      
    }
  
   }

   useEffect(() => {
    GetDetails();
  }, []);

  //.......................................................
const navigate=useNavigate();
  const handleBack = () => {
    navigate("/supplier_leager_table");
  };

  const [showDiv, setShowDiv] = useState(true);

  const handlePrint = () => {
    setShowDiv(false);
    setTimeout(() => {
      window.print();
      setShowDiv(true);
    }, 0);
  };

  useEffect(() => {
    const handleBeforePrint = () => {
      setShowDiv(false);
    };

    const handleAfterPrint = () => {
      setShowDiv(true);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);
 
  return (
    <>
    {loading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "100px" ,backgroundColor:""}}>
          Loading...
        </div>
      ) : (
          <div style={{background:"white"}}>
             
          <>
  <meta charSet="utf-8" />
  <link rel="stylesheet" href="bootstrap.css" />
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n   \n      .invoice-box {\n        max-width: 210mm;\n        margin: auto;\n        height: 260mm;\n       \n        border: 2px solid black;\n          position: relative;\n   \n      \n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        color: #555;\n      }\n\n      .invoice-header {\n        max-width: 210mm;\n    \n        font-size: 16px;\n        line-height: 24px;\n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        color: #555;\n      }\n\ntable {\n  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n}\n\n\n\n      \n\n      @media only screen and (max-width: 600px) {\n        .invoice-box table tr.top table td {\n          width: 100%;\n          display: block;\n          text-align: center;\n          margin: 5px;\n        }\n\n        .invoice-box table tr.information table td {\n          width: 100%;\n          display: block;\n          text-align: center;\n\n        }\n        .tables tfoot\n      {\n         display: table-footer-group;\n      }\n      }\n\n      /** RTL **/\n      .invoice-box.rtl {\n        direction: rtl;\n        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n      }\n\n           .tables {\n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        height: 480px;\n\n        width: 100%;\n\n      }\n     \n\n      .tables tfoot\n      {\n        bottom: 0;\n        \n      }\n\n      td, th {\n        border: 1px solid black;\n\n        text-align: left;\n        padding: 2px;\n      }\n\n\n    "
    }}
  />
 
  <div style={{ margin: 'auto', padding: '30px 24%', position: 'relative' }}>
       
       {showDiv && (
         <>
           <div style={{ margin: "auto", padding: "0px 0px", position: "relative", maxWidth: "210mm" }}>
             <div style={{ float: "left" }}>
               <button className='btn btn-outline-primary' onClick={handleBack}>Back</button>
             </div>
             <div style={{ float: "right" }}>
               <button className='btn btn-outline-danger' onClick={handlePrint}><FaPrint /></button>
             </div>
           </div>
         </>
       )}
     </div>
  <div className="invoice-boxxx ">
    <div className="invoice-header">
      <center>
        <h2>Mobile City</h2>
      </center>
      <center>
        <h4>Suppiler Ledger Summery</h4>
      </center>
      <table style={{ tableLayout: "fixed" }}>  
        <tbody>
          <tr>
            <td>
              Suppiler Name:<label>{suppilerName}</label>
            </td>
            <td>
              Address:<label>{address}</label>
            </td>
          </tr>
          <tr>
            <td>
              Contact:<label>{mobileNumber}</label>
            </td>
            <td>
               From: {date1} / To: {date2}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
   
    <table className="tables" >
      <thead >
        <tr >
          <th>Date</th>
          <th>Perticular</th>
          
          <th>Credit</th>
          <th>Debit</th>
          <th >Balance</th>
     
          
        </tr>
      </thead>
      <tbody>
      
       
      {Array.isArray(tableData) &&
          tableData.map((row, index) => {
            const balanceRemaining = row.sub_total - row.payable_amount;
            runningBalance = runningBalance + balanceRemaining; // Update the running balance
            setBalance(runningBalance)
            return (
              <tr key={index}>
                <td>{row.date}</td>
                <td>{row.invoice_no}</td>
                <td>&#8377;{row.sub_total}</td>
                <td>&#8377;{row.payable_amount}</td>
                <td>&#8377;{runningBalance}</td> 
              </tr>
            );
          })}
        <tr style={{ height: "100%" }}>
          <td />
          <td />
          <td />
          <td />
          <td />
      
        </tr>
      </tbody>
      <tfoot style={{ bottom: 0 }}>
        <tr>
          <th>Date</th>
          <th>Perticular</th>
        
          <th>Credit</th>
          <th>Debit</th>
          <th>Balance  </th>
        
          
        </tr>
      </tfoot>
    </table>
    
  </div>
  
</>

 
    </div>
    )} 
    </>
  )
}
