import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

export default function Sale() {
  const [imeiNotFound, setImeiNotFound] = useState(false);
  //............set  todays date

  const today = new Date().toISOString().split("T")[0];
  //............Customer Details
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState(today);
  const [customerName, setCustomerName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");

  //............Buy Back Details

  const [buyBackIemi, setBuyBacIemi] = useState("");
  const [buyBackModel, setBuyBackModel] = useState("");
  let [buyBackPrice, setBuyBackPrice] = useState("");
  let [buyBackModel1, setBuyBackModel1] = useState("");
  const [buyBackColor,setBuyBackColor] = useState("");
  //..............Brand Details

  
  const [modelName1, setModelName1] = useState("");
  const [imei, setImei] = useState("");
  const [price, setPrice] = useState("");
  const [color,setColor] = useState("");

  const [accessories, setAccessories] = useState("Charger, Bill Box");

  //................Payment Details
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState("");
  let [grantTotal, setGrantTotal] = useState(0);
  const [payableAmount, setPayableAmount] = useState("");
  const [payableAmount1, setPayableAmount1] = useState("");
  const [paymentMode, setPaymentMode] = useState("Cash");
  const [paymentMode1, setPaymentMode1] = useState("");
  let [balance, setBalance] = useState(0);

  const [tableData, setTableData] = useState([]);

  //  .............brand list hide after refreshing
  const [shouldFetchData, setShouldFetchData] = useState(false);

  // ................[ Add brand details {table}  ]......................

  const apiUrl = Config.apiUrl;

  const addBrandDetails = async (e) => {
    e.preventDefault();
    let item = {
      invoice_no: invoiceNumber,

      model_name: modelName1,
      imei: imei,
      price: price,
      color: color,
      accessories: accessories,
    };

    try {
      let result = await fetch(`${apiUrl}/sales_items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.ok) {
        toast.success("sales added successfully");
        console.log(accessories);
        // Add the new brand details to the tableData state
        setTableData((prevTableData) => [...prevTableData, item]);

        // Clear the input fields

        setModelName1("");
        setImei("");
        setPrice("");
         setColor("");
        // Calculate subtotal
        const pprice = parseFloat(price);
        const newSubtotal = pprice + subTotal;
        setSubTotal(newSubtotal);
      } else {
        toast.error("Failed to add sales item");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add sales item");
    }
  };

  //-----------------------[ get Sales items in {table} ] --------------------

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_salesItems`);
        const data = await response.json();
        setTableData(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };
    //.............. table remove after refresh........
    if (shouldFetchData) {
      fetchData();
    }
  }, [shouldFetchData]);

  // .......................[ Add All Sales Details ]------------------------

  const addAllDetails = async (e) => {
    e.preventDefault();
    let item = {
      invoice_no: invoiceNumber,
      date: date,
      customer_name: customerName,
      mobile_no: mobileNumber,
      address: address,
      sub_total: subTotal,
      discount: discount,
      grand_total: grantTotal,
      payable_amount: payableAmount,
      payment_mode: paymentMode,
      
      payable_amount_2: payableAmount1,
      payment_mode_2:paymentMode1
    };

    try {
      let result = await fetch(`${apiUrl}/sales_details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.ok) {
        toast.success("sales items added successfully");
        setTableData([]);
        handleView(invoiceNumber, e);

        setInvoiceNumber("");
        setDate("");
        setCustomerName("");
        setSubTotal("");
        setDiscount("");

        setPayableAmount("");
        setPaymentMode("");
        setMobileNumber("");
        setAddress("");

        // Calculate grand total
        setGrantTotal(subTotal);
      } else {
        toast.error("Failed to add sales");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add sales");
    }
  };

  // ...................[ add Buy Back Details ]------------------------

  const addBuyBackDetails = async (e) => {
    e.preventDefault();
    let item = {
      invoice_no: invoiceNumber,

      model_name: buyBackModel1,
      imei: buyBackIemi,
      color: buyBackColor,
      price: buyBackPrice,
    };

    try {
      let result = await fetch(`${apiUrl}/returnBrand`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.ok) {
        toast.success("Buy Back items added successfully");

        setBuyBacIemi("");
        setBuyBackModel("");
        setBuyBackColor("");
        setBuyBackModel1("");
        setBuyBackPrice("");

        // Calculate subtotal [buyback]
        const bbprice = parseFloat(buyBackPrice);
        const newSubtotal = subTotal - bbprice;
        setSubTotal(newSubtotal);
      } else {
        alert("Failed to add Buy Back items");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add Buy Back items");
    }
  };

  //................{ using IMEI  set Price } .....................

  const [priceList, setPriceList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_purchaseItems`);
        const data = await response.json();
        setPriceList(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  
  //....................[ Update the price,model name,color when IMEI changes].................

  const updatePrice = (imei) => {
    const selectedImei = priceList.find((item) => item.imei === imei);
    if (selectedImei) {
      const newPrice = selectedImei.sale_price;
      const newModel = selectedImei.model_name;
      const newColor = selectedImei.color;
      setPrice(newPrice);
      setModelName1(newModel);
      setColor(newColor);

      setImeiNotFound(false); // Reset the IMEI not found flag
    } else {
      setPrice(0);
      setModelName1(0);
      setColor('');

      setImeiNotFound(true); // Set the IMEI not found flag
    }
  };

  //............ fetch data for dropdown........

  const [modelList, setModelList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_all_models`);
        const data = await response.json();
        setModelList(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  //........................[ navigate]

  const navigate = useNavigate();
  const handleView = async (id, event) => {
    event.stopPropagation(); // Stop event propagation
    const invoice = id;
    navigate(`/invoice_sale?invoice_no=${invoice}`);
  };
  return (
    <>
    <ToastContainer autoClose="1500" />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Sale Details</h4>

                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Customer Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Invoice Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="basic-default-fullname"
                          placeholder="Enter Invoice Number"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="basic-default-fullname"
                          placeholder="Enter date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-email"
                        >
                          Customer Name
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            id="basic-default-email"
                            className="form-control"
                            placeholder="Enter Customer Name"
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Mobile Number"
                          maxLength={10}
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Address
                        </label>
                        <textarea
                          id="basic-default-message"
                          className="form-control"
                          placeholder="Enter Address"
                          defaultValue={""}
                          rows={1}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <hr className="mb-3 mt-3" />
                      <h5 className="mb-4">Buy Back Details</h5>

                      <div className="col-md-6 mb-3">
                      <div className="row">
    <div className="col-md-8 mb-3">
      <label className="form-label" htmlFor="basic-default-company">
        Model Name
      </label>
      <div className="row">
        <div className="col-md-12">
          <input
            type="text"
            className="form-control"
            list="browsers"
            name="browser"
            id="browser"
            placeholder="Enter Model Name"
            value={buyBackModel1}
            onChange={(e) => setBuyBackModel1(e.target.value)}
          />
          <datalist id="browsers">
            {modelList.map((model, index) => (
              <option key={index} value={model.model_name} />
            ))}
          </datalist>
        </div>
      </div>
    </div>
    <div className="col-md-4 mb-3">
      <label className="form-label" htmlFor="basic-default-company">
        Color
      </label>
      <input
        type="text"
        className="form-control"
        id="basic-default-company"
        placeholder="Enter Color"
        value={buyBackColor}
        onChange={(e) => setBuyBackColor(e.target.value)}
      />
    </div>
  </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          IMEI
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter IMEI"
                          value={buyBackIemi}
                          onChange={(e) => setBuyBacIemi(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Price
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Price"
                          value={buyBackPrice}
                          onChange={(e) => setBuyBackPrice(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 mt-4 d-flex justify-content-end">
  <button
    type="button"
    className="btn btn-primary"
    style={{ marginLeft: "5px", marginRight: "5px" }}
    onClick={addBuyBackDetails}
  >
    Buy Back
  </button>
</div>

                      
                      <hr className="mb-3 mt-3" />

                      <h5 className="mb-4">Brand Details</h5>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label className="form-label" htmlFor="basic-default-company">
                              Model Name
                            </label>
                            <div className="row">
                          <div className="col-md-12 mb-3">
                          <input
                          type="text"
                          className="form-control"
                          id="basic-default-company"
                          placeholder="Enter Model Name"
                         value={modelName1}
                          onChange={(e) => setModelName1(e.target.value.toUpperCase())}
                        />
                            
                          </div>
                          {/* <div className="col-md-3 mb-3">
                          <select
                          className="form-select"
                          id="basic-default-company"
                          value={modelName}
                          onChange={(e) => {
                            setModelName(e.target.value);
                            setModelName1(e.target.value); // Update both modelName and modelName1
                          }}
                        >
                          <option value="" disabled>
                            Select model name
                          </option>
                          {modelList.map((model, index) => (
                            <option key={index} value={model.model_name}>
                              {model.model_name}
                            </option>
                          ))}
                        </select>
                          </div> */}
                        </div>
                          </div>
                          <div className="col-md-6 mb-3">
                          <label className="form-label" htmlFor="basic-default-company">
                          Color
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="basic-default-company"
                          placeholder="Enter Color"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          IMEI
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter IMEI"
                          value={imei}
                          onChange={(e) =>
                            setImei(e.target.value, updatePrice(e.target.value))
                          }
                        />
                        {imeiNotFound && <span style={{color:"red"}}>IMEI not found</span>}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Price
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Accessories
                        </label>
                        <textarea
                          id="basic-default-message"
                          className="form-control"
                          placeholder="Enter description"
                          rows={1}
                          value={accessories}
                          onChange={(e) => setAccessories(e.target.value)}
                        />
                      </div>

                      <div className="col-12">
                        <div className="col-12 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginLeft: "5px", marginRight: "5px" }}
                            onClick={addBrandDetails}
                          >
                            Add
                          </button>
                        </div>
                        {tableData.length > 0 ? (
                          <div className="card mt-3">
                            <h5 className="card-header">Brand List</h5>

                            <div className="table-responsive text-nowrap">
                              <table className="table">
                                <thead>
                                  <tr className="text-nowrap">
                                    <th>SN</th>

                                    <th>Model Name</th>
                                    <th>IMEI</th>
                                    <th> Price</th>

                                    <th>Accessories</th>

                           
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(tableData) &&
                                    tableData.map((row, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>

                                        <td>{row.model_name}</td>
                                        <td>{row.imei}</td>
                                        <td>{row.price}</td>

                                        <td>{row.accessories}</td>
                                        
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div className=""></div>
                        )}
                      </div>
                      <hr className="mb-3 mt-3" />
                      <h5 className="mb-4">Payment Details</h5>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Sub Total
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Sub Total"
                          value={subTotal}
                          onChange={(e) => setSubTotal(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Discount
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Discount"
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Grant Total
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Grant Total"
                          value={(grantTotal = subTotal - discount)}
                          onChange={(e) => setGrantTotal(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Cash Amount
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Payable Amount
                                "
                          value={payableAmount}
                          onChange={(e) => setPayableAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                      <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payment Mode
                        </label>
                        <input
                          id="basic-default-message"
                          className="form-control"
                          placeholder=""
                          rows={1}
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                        />
                      </div>
                      
                      <div className="col-md-3 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Online Amount
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Payable Amount
                                "
                                value={payableAmount1}
                          onChange={(e)=>setPayableAmount1(e.target.value)}
                         
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payment Mode
                        </label>
                        <select
                          className="form-select"
                          id="basic-default-company"
                          defaultValue=""
                          value={paymentMode1}
                          onChange={(e) => setPaymentMode1(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Payment Mode
                          </option>
                          <option value="Debit Card">UPI</option>
                          <option value="Credit Card">Credit Card</option>
                          <option value="Debit Card">Debit Card</option>
                          <option value="Debit Card">Net Banking</option>
                         
                        </select>
                      </div>
                      
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Balance Amount
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="
                                "
                                value={balance=Number(grantTotal)-Number(payableAmount)-Number(payableAmount1)}
                                onChange={(e)=>setBalance(e.target.value)}
                          
                        />
                      </div>

                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          onClick={addAllDetails}
                        >
                          Submit
                        </button>

                        <button type="submit" className="btn btn-danger">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />

              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}
