import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Config from "../Header/Config";
export default function Dailybook_update() {
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [credit, setCredit] = useState("");
  const [debit, setDebit] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [note, setNote] = useState("");
//............................................................................................................
  const navigate = useNavigate();
  const apiUrl = Config.apiUrl;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const _id = searchParams.get("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (_id) {
          const response = await fetch(`${apiUrl}/searchSingleEntry/${_id}`);
          const data = await response.json();
          console.log(data);
          setName(data.data.entry_name);
          setDate(data.data.date);
          setCredit(data.data.creadit_amount);
          setDebit(data.data.debit_amount);
          setNote(data.data.note);
          setPaymentMode(data.data.payment_mode);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [_id]);
//...................................................................................................................
  const [updatedDetails, setUpdatedDetails] = useState([]);
  const updateDailybookDetails = async () => {
    const item = {
      creadit_amount: credit,
      date: date,
      debit_amount: debit,
      entry_name: name,
      
      note: note,
      
      payment_mode: paymentMode,
     
    };

    try {
      const result = await fetch(`${apiUrl}/createEntry/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.ok) {
        alert("Dailybook updated successfully");

        setUpdatedDetails(updatedDetails);

        setName("");
        setDebit("");
        setCredit("");
        setNote("");
        setPaymentMode("");

        navigate("/dailybook_list");
      } else {
        alert("Failed to update dailybook");
      }
    } catch (error) {
      console.log(error);
      alert("Failed to update dailybook");
    }
  };
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <div className="content-wrapper">
              {/* ........................................................................................................[Purchase Details].........................       */}
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Daily Book Update</h4>

                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Daily Book Update</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-fullname"
                        >
                          Name Of Entry
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="basic-default-fullname"
                          placeholder="Enter Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-company"
                        >
                          Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="basic-default-date"
                          placeholder="Enter date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-email"
                        >
                          Credit Amount
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            type="number"
                            id="basic-default-email"
                            className="form-control"
                            placeholder="Enter Amount"
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            value={credit}
                            onChange={(e) => setCredit(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Debit Amount
                        </label>
                        <input
                          type="number"
                          id="basic-default-phone"
                          className="form-control phone-mask"
                          placeholder="Enter Amount"
                          value={debit}
                          onChange={(e) => setDebit(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Payment Mode
                        </label>
                        <select
                          className="form-select"
                          id="basic-default-company"
                          defaultValue=""
                          value={paymentMode}
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Payment Mode
                          </option>
                          <option value="Credit Card">Credit Card</option>
                          <option value="Debit Card">Debit Card</option>
                          <option value="Net Banking">Net Banking</option>
                          <option value="UPI">UPI</option>
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-phone"
                        >
                          Note
                        </label>
                        <input
                          type="text"
                          id="basic-default-message"
                          className="form-control"
                          placeholder="Enter Note"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>
                      {/* ....................................................................................................................................  */}

                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          onClick={() => updateDailybookDetails()}
                        >
                          Update
                        </button>
                        <button type="button" className="btn btn-danger">
                          Cancel
                        </button>
                      </div>
                      {/* .................................................................................................................................                        */}
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
