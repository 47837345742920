import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Header/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import Config from '../Header/Config';
import { ToastContainer, toast } from 'react-toastify';


export default function Model_update() {

          const [model, setModel] = useState("");
          const [color, setColor] = useState("");
          const [openingStock, setOpeningStock] = useState('');
          const [description, setDescription] = useState("");

          const navigate = useNavigate();
          const apiUrl = Config.apiUrl;
          
          const location = useLocation();
          const searchParams = new URLSearchParams(location.search);
          const _id = searchParams.get("id");
 //..............................................................................[get value in feild]....................................       
        useEffect(() => {
  const fetchData = async () => {
    try {
      if (_id) {
        const response = await fetch(`${apiUrl}/get_models/${_id}`);
        const data = await response.json();
        console.log(data);
        setModel(data.data.model_name);
        setColor(data.data.color_name);
        setOpeningStock(data.data.opening_stock); 
        setDescription(data.data.description);
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, [_id]);


//.....................................................................[update ]........................

          const [updatedDetails, setUpdatedDetails] = useState([]);
  const updateModelDetails = async () => {
    const item = {
      model_name: model.toUpperCase(),
      color_name: color,
      opening_stock: openingStock,
      description: description,
     
    };

    try {
      const result = await fetch(`${apiUrl}/save_model/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      });

      if (result.ok) {
        toast.success("model updated successfully",{ autoClose: 1500 });

        setUpdatedDetails(updatedDetails);

        setModel('');
        setColor('');
        setOpeningStock('');
        setDescription('');

        navigate("/model_table");
      } else {
        toast.error("Failed to update model");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to update model");
    }
  };
  return (
          <>
          <ToastContainer/>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <Header/>
    
              <div className="layout-page">
                <div className="content-wrapper">
                  <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="fw-bold py-3 mb-4">Model Profile</h4>
    
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Model Profile</h5>
                      </div>
                      <div className="card-body">
                        <div>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-email"
                              >
                                Model
                              </label>
                              <div className="input-group input-group-merge">
                                <input
                                  type="text"
                                  id="basic-default-email"
                                  className="form-control"
                                  placeholder="Enter Model Name"
                                  aria-label="john.doe"
                                  aria-describedby="basic-default-email2"
                                  value={model}
                                  onChange={(e) => setModel(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-phone"
                              >
                                Color
                              </label>
                              <input
                                type="text"
                                id="basic-default-phone"
                                className="form-control phone-mask"
                                placeholder="Enter Color name"
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                              />
                            </div>
    
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-phone"
                              >
                                Opening Stock
                              </label>
                              <input
                                type="number"
                                id="basic-default-phone"
                                className="form-control phone-mask"
                                placeholder="Enter Opening Stock"
                                value={openingStock}
                                onChange={(e) => setOpeningStock(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="basic-default-phone"
                              >
                                Description
                              </label>
                              <input
                              type='text'
                                id="basic-default-message"
                                className="form-control"
                                placeholder="Enter description"
                            
                                value={description}
                        
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </div>
    
                            <div className="col-12">
                              <div className="col-12 d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{ marginLeft: "5px", marginRight: "5px" }}
                           onClick={()=>updateModelDetails()}
                                >
                                  Update   
                                </button>
    
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                 
                                >
                                  Cancel 
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <Footer />
    
                  <div className="content-backdrop fade" />
                </div>
              </div>
            </div>
    
            <div className="layout-overlay layout-menu-toggle" />
          </div>
        </>
  )
}
