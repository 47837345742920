import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import {  FaUserCircle } from "react-icons/fa";

function Header() {
  const [brandMenuOpen, setBrandMenuOpen] = useState(false);
  const [suppilerMenuOpen, setSuppilerMenuOpen] = useState(false);
  const [colorMenuOpen, setColorMenuOpen] = useState(false);
  const [customerMenuOpen, setCustomerMenuOpen] = useState(false);
  const [purchaseMenuOpen, setPurchaseMenuOpen] = useState(false);
  const [saleMenuOpen, setSaleMenuOpen] = useState(false);
const [dailyMenuOpen, setDailyOpen] = useState(false);
  const handleToggleBrandMenu = () => {
    setBrandMenuOpen(!brandMenuOpen);
  };

  const handleToggleSuppilerMenu = () => {
    setSuppilerMenuOpen(!suppilerMenuOpen);
  };

  const handleToggleColorMenu = () => {
    setColorMenuOpen(!colorMenuOpen);
  };

  const handleToggleCustomerMenu = () => {
    setCustomerMenuOpen(!customerMenuOpen);
  };

  const handleTogglePurchaseMenu = () => {
    setPurchaseMenuOpen(!purchaseMenuOpen);
  };

  const handleToggleSaleMenu = () => {
    setSaleMenuOpen(!saleMenuOpen);
  };

  const handleToggleDaily = () => {
    setDailyOpen(!dailyMenuOpen);
  };
//logout
  const username = localStorage.getItem('username');
  const isLoggedIn=localStorage.getItem('isLoggedIn');
  const {
    handleLogout,

  }=useAuth();

  const logOut =()=>{

   handleLogout();


  }
  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand demo">
          <a href="/" className="app-brand-link">
            <span className="app-brand-logo demo">
              <svg
                width={25}
                viewBox="0 0 25 42"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                {/* SVG code */}
              </svg>
            </span>
            <span className="app-brand-text demo menu-text fw-bolder ms-2">
              Mobile City
            </span>
          </a>
          <a
            href="/"
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle" />
          </a>
        </div>
        <div className="menu-inner-shadow" />
        <ul className="menu-inner py-1 scroll">
          <li className="menu-item active">
            <Link to="/" className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-circle" />
              <div data-i18n="Analytics">Dashboard</div>
            </Link>
          </li>
          {username === 'admin' && (
          <li className="menu-item ">
            <Link to="/user" className="menu-link">
              <FaUserCircle style={{ marginRight: "12px" }} />
              <div data-i18n="Analytics ">Manage User</div>
            </Link>
          </li>
          )}
          
          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Pages</span>
          </li>
         
         {/* <hr style={{height:"1px",color:"black",width:"261px"}}/> */}
          <li className={`menu-item ${brandMenuOpen ? "open" : ""}`}>
            <div
              className="menu-link menu-toggle1"
              onClick={handleToggleBrandMenu}
            >
              <i className="menu-icon tf-icons bx bx-layout" />
              <div data-i18n="Layouts">Model Details</div>
            </div>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/model" className="menu-link">
                  <div data-i18n="Without menu">Model</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/model_table" className="menu-link">
                  <div data-i18n="Without navbar">Model List</div>
                </Link>
              </li>
            </ul>
          </li>

          <li className={`menu-item ${suppilerMenuOpen ? "open" : ""}`}>
            <div
              className="menu-link menu-toggle1"
              onClick={handleToggleSuppilerMenu}
            >
              <i className='menu-icon bx bx-user'></i>
              <div data-i18n="Layouts">Suppiler Details</div>
            </div>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/suppiler" className="menu-link">
                  <div data-i18n="Without menu">Add Suppiler</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/suppiler_table" className="menu-link">
                  <div data-i18n="Without navbar">Suppiler List</div>
                </Link>
              </li>
            </ul>
          </li>
           
           
          <li className={`menu-item ${purchaseMenuOpen ? "open" : ""}`}>
            <div
              className="menu-link menu-toggle1"
              onClick={handleTogglePurchaseMenu}
            >
              <i className="menu-icon bx bx-purchase-tag-alt" />
            
              <div data-i18n="Layouts">Purchase Details</div>
            </div>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/purchase" className="menu-link">
                  <div data-i18n="Without menu">Purchase</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/purchase_table" className="menu-link">
                  <div data-i18n="Without navbar">Purchase List</div>
                </Link>
              </li>
              {/* <li className="menu-item">
                <Link to="/purchase_invoice" className="menu-link">
                  <div data-i18n="Without navbar">Purchase Invoice</div>
                </Link>
              </li> */}
            </ul>
          </li>
          <li className={`menu-item ${saleMenuOpen ? "open" : ""}`}>
            <div
              className="menu-link menu-toggle1"
              onClick={handleToggleSaleMenu}
            >
              <i className="menu-icon bx bx-credit-card-front" />
              <div data-i18n="Layouts">Sale Details</div>
            </div>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/sale" className="menu-link">
                  <div data-i18n="Without menu">Sale</div>
                </Link>
              </li> 
              <li className="menu-item">
                <Link to="/sale_table" className="menu-link">
                  <div data-i18n="Without navbar">Sale List</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/sale_buyback_table" className="menu-link">
                  <div data-i18n="Without navbar">Buy Back List</div>
                </Link>
              </li>
              {/* <li className="menu-item">
                <Link to="/invoice_sale" className="menu-link">
                  <div data-i18n="Without navbar">Invoice Sale</div>
                </Link>
              </li> */}
            </ul>
          </li>
          <li className={`menu-item ${colorMenuOpen ? 'open' : ''}`}>
            <div className="menu-link menu-toggle1" onClick={handleToggleColorMenu}>
              <i className="menu-icon bx bx-wallet" />
              <div data-i18n="Layouts">Payable Details</div>
            </div>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/purchase_payable" className="menu-link">
                  <div data-i18n="Without menu">Purchase Payable</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/purchase_payable_table" className="menu-link">
                  <div data-i18n="Without navbar">Purchase Payable List</div>
                </Link>
              </li>
            </ul>
          </li>
          <li className={`menu-item ${customerMenuOpen ? "open" : ""}`}>
            <div
              className="menu-link menu-toggle1"
              onClick={handleToggleCustomerMenu}
            >
              <i className="menu-icon bx bx-detail" />
              <div data-i18n="Layouts">Leager Details</div>
            </div>
            <ul className="menu-sub">
              
              <li className="menu-item">
                <Link to="/supplier_leager_table" className="menu-link">
                  <div data-i18n="Without navbar">Suppiler Leager</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/dailybook_leager_list" className="menu-link">
                  <div data-i18n="Without navbar">Dailybook Leager</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/general_leager_table" className="menu-link">
                  <div data-i18n="Without navbar">General Leager</div>
                </Link>
              </li>
              
            </ul>

          </li>
          <li className={`menu-item ${dailyMenuOpen ? 'open' : ''}`}>
            <div className="menu-link menu-toggle1" onClick={handleToggleDaily}>
            <i
                className="menu-icon bx bxs-book-open"
               
              />
              <div data-i18n="Layouts">Daily Book</div>
            </div>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/dailybook" className="menu-link">
                  <div data-i18n="Without menu">Daily Book</div>
                </Link>
              </li>
              <li className="menu-item">

                <Link to="/dailybook_list" className="menu-link">
                  <div data-i18n="Without navbar">Daily Book List</div> 
                </Link>

              </li>
            </ul>
          </li>
          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Reports</span>
          </li>
          <li className="menu-item ">
            <Link to="/purchase_report" className="menu-link">
            
            <i class='bx bxs-purchase-tag-alt'style={{ marginRight: "12px" }} ></i>
              <div data-i18n="Analytics ">Purchase Report</div>
            </Link>
          </li>
          <li className="menu-item ">
            <Link to="/sale_report" className="menu-link">
            
            <i class='bx bxs-credit-card-front' style={{ marginRight: "12px" }} />
              <div data-i18n="Analytics ">Sale Report</div>
            </Link>
          </li>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text"></span>
          </li>
         
          {isLoggedIn === 'true' && (
  <li className="menu-item active">
    <div to="/" className="menu-link">
     
    
      <div data-i18n="Analytics" onClick={logOut}>
      <i className="bx bx-power-off me-2 " style={{ marginRight: "12px" ,color:"red"}} />
        Logout
      </div>
    </div>
  </li>
)}

          
        </ul>
      </aside>
    </>
  );
}

export default Header;
