
import React from 'react'

function Footer() {
  return (
<>
<footer className="content-footer footer bg-footer-theme">
            <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
              <div className="mb-2 mb-md-0">
              Copyright ©2023 Designed & Developed ❤️ by
                <span className='ml-2'style={{marginLeft:"10px"}}></span><a
                    href="https://sparklabitsolutions.co.in/"
                  target="_blank"
                  className="footer-link fw-bolder"
                >
                  Sparklab IT Solutions Pvt.Ltd
                </a>
              </div>
              <div>
                
              </div>
            </div>
          </footer>
</>
  )
}
export default Footer;