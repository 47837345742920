import React, { useEffect, useMemo, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaEye, FaRegEye, FaShoppingCart, FaTrash, FaTrashAlt } from "react-icons/fa";
const ITEMS_PER_PAGE = 10 ; // Number of items to display per page
export default function Purchase_table() {
  const [tableData, setTableData] = useState([]);
  const [brand, setBrand] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = Config.apiUrl;
  //................................................................[ fetch all data ]...........................................................
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [purchaseDetailsResponse, purchaseItemsResponse] = await Promise.all([
          fetch(`${apiUrl}/get_all_purchaseDetails`),
          fetch(`${apiUrl}/get_all_purchaseItems`)
        ]);
  
        const purchaseDetailsData = await purchaseDetailsResponse.json();
        const purchaseItemsData = await purchaseItemsResponse.json();
  
        setTableData(purchaseDetailsData.data.reverse());
        setBrand(purchaseItemsData.data.reverse());
        setIsLoading(false);
        console.log(purchaseDetailsData);
        console.log(purchaseItemsData);
      } catch (error) {
        console.error(error);
      }finally{
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);


  //.................................................................. [ combine 2 api's data ].....................................
  const combinedData = useMemo(() => {
    if (brand.length === 0 || tableData.length === 0 ) {
      return [];
    }
  
    return brand.map((row) => {
      const brandItem = tableData.find((item) => item.invoice_no === row.invoice_no);
  
      if (brandItem) {
        return {
          ...row,
          date: brandItem.date,
          supplier_name: brandItem.supplier_name,
        };
      }
  
      return row;
    });
  }, [brand, tableData]);

  //..............................................................

  const navigate = useNavigate();

  const handleView = async (id) => {
    const invoice = id;
    navigate(`/purchase_invoice?invoice_no=${invoice}`);
  };

//................................................................
  
  const handleUpdate = async (id, event) => {

    const invoice = id;
    navigate(`/purchase_update?invoice_no=${invoice}`);
  };

//..................................................................

 // Delete Record from database
 const handleDelete = (id) => {
  
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (confirmDelete) {
      fetch(`${apiUrl}/delete_purchaseDetails/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
     // Set the new status value to 1 (deleted)
      })
    
        .then((response) => response.json())
        .then(() => {
          
          alert(id)
          // Reload the page to reflect the updated data (with the deleted row removed)
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error updating status:", error);
        });
    }
  };

//............................[ search box]..............................................
const [searchQuery, setSearchQuery] = useState(""); // State variable for the search query

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1)
  };

  const filteredTableData = combinedData.filter((row) =>
  (row.model_name && row.model_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
  (row.purchase_price && row.purchase_price.toString().includes(searchQuery.toString())) ||
  (row.invoice_no && row.invoice_no.toString().includes(searchQuery.toString())) ||
  (row.supplier_name && row.supplier_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
  (row.date && row.date.toString().includes(searchQuery.toString())) 
);

  
   //..................................[ Pagination ].........................................

  
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages based on the data and items per page
  const totalPages = Math.ceil(filteredTableData.length / ITEMS_PER_PAGE);

  // Calculate the index range for the items to be displayed on the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredTableData.length);

 // Function to handle pagination button clicks
const handlePageChange = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= totalPages) {
    setCurrentPage(pageNumber);
  }
};
 

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />

          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">Purchase List</h4>

                <hr className="my-5" />
                {/* Search Box */}
                <div style={{ display: "flex", justifyContent: "end" }}>
                 <div className="mb-3 col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                    </div>

                <div className="card">
                  <h5 className="card-header">Purchase List</h5>
                  <div
                    className="table-container"
                    style={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                   
                    {isLoading ? (
       
       <div className="d-flex justify-content-center mb-5">
       <div className="spinner-border text-primary" role="status">
         <span className="sr-only "></span>
       </div>
     </div>
        
      ) : (
                    <table className="table">
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        <tr className="text-nowrap">
                          <th>Sr</th>
                          <th>Model Name</th>
                          <th>Color</th>
                          <th>IMEI</th>
                          <th>Purchase Price</th>
                          
                          <th>ACTION</th>
                        </tr>
                      </thead>

                      <tbody>
                      {Array.isArray(filteredTableData) &&  filteredTableData.length>0 ? (
            filteredTableData.slice(startIndex, endIndex).map((row, index) => (
                          
                            <tr key={index}>
                              <td>{index +1}</td>
                              <td>{row.model_name}</td>
                              <td>{row.color}</td>
                              <td>{row.imei}</td>
                              <td>{row.purchase_price}</td>
                              

                              <td>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => handleView(row.invoice_no)}
                                >
                                  <FaEye />
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => handleUpdate(row.invoice_no)}
                                >
                                  <FaEdit />
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <FaTrashAlt />
                                </button>
                               
                              </td>
                            </tr>
                          ))
                          ) : (
                            <tr>
                              <td colSpan="6">No data available</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
      )}
                  </div>
                </div>
                  {/* Pagination */}
<div style={{ display: "flex", justifyContent: "end", padding: "10px 10px" }}>
  <nav aria-label="Page navigation">
    <ul className="pagination">
      {/* Previous arrow button */}
      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
      </li>

      {/* Page numbers */}
      {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
        // Render the page number button only if it's within the range of visible pages
        (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2) && (
          <li
            key={pageNumber}
            className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        )
      ))}

      {/* Next arrow button */}
      <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </li>
    </ul>
  </nav>
</div>
              </div>

              <Footer />

              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle" />
      </div>
    </>
  );
}
