import React, { useEffect, useState } from 'react'
import Config from '../Header/Config';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPrint } from 'react-icons/fa';
//......................................................................................
const filterDataBetweenDates = (data, fromDate, toDate) => {
  if (!data || data.length === 0) return [];
  return data.filter((entry) => entry.date >= fromDate && entry.date <= toDate);
};
//......................................................................................
export default function Dailybook_leager() {

  const apiUrl=Config.apiUrl
  const [filteredData, setFilteredData] = useState([]);
const [loading ,isLoading] = useState(true  )
  const[total,setTotal] = useState(0)
  const[debitTotal, setDebitTotal]=useState(0)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/searchAllEntry?from=${from}&to=${to}`);
        const data = await response.json();
  
        console.log(data);
      //.......................................................................................

        const filteredData = filterDataBetweenDates(data.data, from, to);
        setFilteredData(filteredData);
        isLoading(false)
        console.log(filteredData);

        const sumOfCredits = filteredData.reduce(
          (sum, entry) => sum + Number(entry.creadit_amount),
          0
        );

        setTotal(sumOfCredits);

        const sumOfDebit = filteredData.reduce(
          (sum, entry) => sum + Number(entry.debit_amount),
          0
        );

        setDebitTotal(sumOfDebit);
       
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [from, to]);

  


  const [showDiv, setShowDiv] = useState(true);

  const handlePrint = () => {
    setShowDiv(false);
    setTimeout(() => {
      window.print();
      setShowDiv(true);
    }, 0);
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/dailybook_leager_list");
  };

  useEffect(() => {
    const handleBeforePrint = () => {
      setShowDiv(false);
    };

    const handleAfterPrint = () => {
      setShowDiv(true);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);
  return (
    <>
      
        
  <meta charSet="utf-8" />
  <link rel="stylesheet" href="bootstrap.css" />
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n   \n      .invoice-box {\n        max-width: 210mm;\n        margin: auto;\n        height: 260mm;\n       \n        border: 2px solid black;\n          position: relative;\n   \n      \n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        color: #555;\n      }\n\n      .invoice-header {\n        max-width: 210mm;\n    \n        font-size: 16px;\n        line-height: 24px;\n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        color: #555;\n      }\n\ntable {\n  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n}\n\n\n\n      \n\n      @media only screen and (max-width: 600px) {\n        .invoice-box table tr.top table td {\n          width: 100%;\n          display: block;\n          text-align: center;\n          margin: 5px;\n        }\n\n        .invoice-box table tr.information table td {\n          width: 100%;\n          display: block;\n          text-align: center;\n\n        }\n        .tables tfoot\n      {\n         display: table-footer-group;\n      }\n      }\n\n      /** RTL **/\n      .invoice-box.rtl {\n        direction: rtl;\n        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n      }\n\n           .tables {\n        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\n        height: 480px;\n\n        width: 100%;\n\n      }\n     \n\n      .tables tfoot\n      {\n        bottom: 0;\n        \n      }\n\n      td, th {\n        border: 1px solid black;\n\n        text-align: left;\n        padding: 2px;\n      }\n\n\n    "
    }}
  />
   {loading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "100px" }}>
          Loading...
        </div>
      ) : (
<section style={{backgroundColor:"white"}}>
<div style={{ margin: 'auto', padding: '30px 24%', position: 'relative',backgroundColor:"white" }}>
        {showDiv && (
          <>
            <div style={{ margin: "auto", padding: "30px 0px", position: "relative", maxWidth: "210mm" }}>
              <div style={{ float: "left" }}>
                <button className='btn btn-outline-primary' onClick={handleBack}>Back</button>
              </div>
              <div style={{ float: "right" }}>
                <button className='btn btn-outline-danger' onClick={handlePrint}><FaPrint /></button>
              </div>
            </div>
          </>
        )}
      </div>
  <div className="invoice-boxxxx" >
    <div className="invoice-header mt-3">
      <center>
        <h2>Mobile City</h2>
      </center>
      <center>
        <h4>Dailybook Ledger</h4>
      </center>
    </div>
    <br />
    
    <table className="tables" style={{}}>
      <thead>
        <tr>
          <th>Sr.NO</th>
          <th>Entry Name</th>
          <th>Date</th>
          <th>Credit</th>
          <th>Debit</th>
          <th>Paid By</th>
 
        </tr>
      </thead>
      <tbody>
       {Array.isArray(filteredData) &&
                filteredData.map((row, index) => (
                  
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row.entry_name}</td>
          <td>{row.date}</td>
          <td>&#8377;{row.creadit_amount}</td>
          <td>&#8377;{row.debit_amount}</td>
          <td>{row.payment_mode}</td>
         
        </tr>
           ))}
        <tr style={{ height: "100%" }}>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
         
        </tr>
        
      </tbody>
      <tfoot style={{ bottom: 0 }}>
        <tr >
          <th />
          <th />
          <th>Total</th>
          <th> ₹{total}</th>
          <th> ₹{debitTotal}</th>
          <th />
      
        </tr>
      </tfoot>
    </table>
  </div>
 
  </section>
      )}     
</>

  )
}
