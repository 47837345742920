import React, { useEffect, useState } from "react";
import Footer from "../Header/Footer";
import Header from "../Header/Header";
import Config from "../Header/Config";
import { FaEdit, FaPrint, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
const ITEMS_PER_PAGE = 10 ; // Number of items to display per page

export default function Leager_suppiler_table() {

          const apiUrl = Config.apiUrl;
          const [isLoading, setIsLoading] = useState(true);
          const [tableData, setTableData] = useState([]);
          const [suppilerName,setSuppilerName] = useState('');
          const [address,setAddress] =useState('');
          const navigate = useNavigate();
        
          useEffect(() => {
            const fetchTableData = async () => {
              try {
                if (tableData.length === 0) {
                  try {
                    const response = await fetch(`${apiUrl}/getSupplier`);
                    const data = await response.json();
                    
                    console.log(data);
                    
                    setTableData(data.data.reverse()); // Reverse the data array before setting it
                    setAddress(data.data.address); // Set the address from the first entry
                    
                    setIsLoading(false);
                  } catch (error) {
                    console.error(error);
                    // Handle the error appropriately, such as showing an error message
                  }
                }
                
                
              } catch (error) {
                console.error(error);
              } finally{
                setIsLoading(false);
              }
            };
        
            fetchTableData();
          }, [apiUrl]);
        
          //...
          //.....................................[ Search box ]...............................................
          const [searchQuery, setSearchQuery] = useState(""); // State variable for the search query
          const handleSearch = (event) => {
            setSearchQuery(event.target.value);
            setCurrentPage(1)
          };
        
          const filteredTableData = tableData.filter((row) => {
            const supplier_name = row.supplier_name || ''; // Default to an empty string if undefined
            const address = row.address || ''; // Default to an empty string if undefined
            const mobileNo = row.mobile_no ? row.mobile_no.toString() : ''; // Convert to string if defined, else use an empty string
            
            return (
              supplier_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              address.toLowerCase().includes(searchQuery.toLowerCase()) ||
              mobileNo.includes(searchQuery.toString())
            );
          });
          
          //..................................[ Pagination ].........................................
        
          
          const [currentPage, setCurrentPage] = useState(1);
        
          // Calculate the total number of pages based on the data and items per page
          const totalPages = Math.ceil(filteredTableData.length / ITEMS_PER_PAGE);
        
          // Calculate the index range for the items to be displayed on the current page
          const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
          const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredTableData.length);
        
         // Function to handle pagination button clicks
        const handlePageChange = (pageNumber) => {
          if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
       
          }
        };

        //.....................................................................................................

        const [date1,setDate1]=useState('');
        const [date2,setDate2]=useState('');
         
        const handlePrint = (mobile_no, date1, date2, supplier_name, address) => {
          navigate(
            `/suppiler_leager?mobileNumber=${mobile_no}&date1=${date1}&date2=${date2}&supplierName=${supplier_name}&address=${address}`
          );
        };
        //.................................................................................................

       
  return (
          <>
          <ToastContainer/>
            <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
                <Header />
                
      
                <div className="layout-page">
                  
                  <div className="content-wrapper">
                    
                    <div className="container-xxl flex-grow-1 container-p-y">
                      
                      <h4 className="fw-bold py-3 mb-4">Suppiler Leager List</h4>
      
                      <hr className="my-3" />
                       {/* Search Box */}
                       <div style={{ display: "flex", justifyContent: "end" }}>
                       <div className="mb-3 col-md-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              value={searchQuery}
                              onChange={handleSearch}
                            />
                          </div>
                          </div>
      
                      <div className="card">
                        <h5 className="card-header">Suppiler Leager List</h5>
                        <div
                          className="table-responsive text-nowrap"
                          style={{ overflowY: "auto", maxHeight: "400px" }}
                        >
                         
      
                          {isLoading ? (
                            <div className="d-flex justify-content-center mb-5">
                              <div className="spinner-border text-primary" role="status">
                                <span className="sr-only "></span>
                              </div>
                            </div>
                          ) : (
                            <table className="table mb-3">
                              <thead
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1,
                                  backgroundColor: "#f5f5f5",
                                }}
                              >
                                <tr className="text-nowrap">
                                  <th>SN</th>
                                  <th>supplier Name</th>
                                  <th>Mobile Number</th>
                                  <th>Address</th>
                                  <th>Date</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                              {Array.isArray(filteredTableData) && filteredTableData.length>0 ? (
                  filteredTableData.slice(startIndex, endIndex).map((row, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.supplier_name}</td>
                                        <td>{row.mobile_no}</td>
                                        <td>{row.address }</td>
                                        <td>
                                                  <input type="date" className="form-control" 
                                                  value={row.date1}
                                                  onChange={(e)=>setDate1(e.target.value)} />
                                                  <input type="date" className="form-control"
                                               value={row.date2}
                                                   onChange={(e)=>setDate2(e.target.value)} />
                                        </td>
                                        <td>
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                        onClick={() => handlePrint(row.mobile_no, date1, date2,row.supplier_name,row.address)}

                                          >
                                            <FaPrint />
                                          </button>
                                          
                                        </td>
                                      </tr>
                                    ))):(
                                      <tr>
                                          <td colSpan="6">No data available</td>
                                      </tr>
                                    )}
                              </tbody>  
                            </table>
                          )}
                        
      
      
      
                        </div>
                      </div>
                      {/* Pagination */}
      <div style={{ display: "flex", justifyContent: "end", padding: "10px 10px" }}>
        <nav aria-label="Page navigation">
          <ul className="pagination">
            {/* Previous arrow button */}
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
      
            {/* Page numbers */}
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
              // Render the page number button only if it's within the range of visible pages
              (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2) && (
                <li
                  key={pageNumber}
                  className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              )
            ))}
      
            {/* Next arrow button */}
            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {">"}
              </button>
            </li>
          </ul>
        </nav>
      </div>
                    </div>
                    
      
                    <Footer />
      
                    <div className="content-backdrop fade" />
                  </div>
                </div>
              </div>
      
              <div className="layout-overlay layout-menu-toggle" />
            </div>
          </>
  )
}
